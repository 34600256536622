//HOOKS
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//SERVICES
import { getHotelService } from "services/hotels";
import { getOperatorService } from "services/operator";
import { getMarketService } from "services/market";
import { getReservationsService } from "services/reservation";
import {
  getAllCountriesService,
  getRegionsService
} from "services/destinations";
//MATERIAL-UI COMPONENTS
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
import HotelIcon from "@mui/icons-material/Hotel";
import SouthAmericaIcon from "@mui/icons-material/SouthAmerica";
import FlagIcon from "@mui/icons-material/Flag";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DiscountIcon from "@mui/icons-material/Discount";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { KeyboardDatePicker } from "@material-ui/pickers";

//SWAL
import Swal from "sweetalert2";
//STYLES
import searchPrice from "./SearchPriceStyle/searchPrice";
import moment from "moment";
import axios from "services/axios";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import { getContractService } from "services/contract";
import { getCurrenciesService } from "services/currency";
import { getBoardsService } from "services/boards";

// DESC - Dummy data
const dummyData = {
  "1 / 1": {
    "3": {
      "AIR CONDITION / 1 / O": {
        "1": {
          "Total Price": 327.6,
          "BS Total": 0,
          "Extra Price": 15,
          "Extra Discount": 0.75,
          "Extra Type": "O",
          "Room Price": 327.6,
          "Discount Price": 5.46,
          Markup: 6.55,
          "Net Price": 328.69,
          Currency: "EUR",
          "Offer Discount / 14": {
            SPDO1: 5.46
          },
          "Offer Dest": "SPO5 (SPDO1 5) 5.46",
          cancelPolicy: {
            "0 - 3": 80,
            "4 - 8": 60,
            "9 - 15": 30
          }
        },
        "2": {
          "Total Price": 234,
          "BS Total": 0,
          "Extra Price": 15,
          "Extra Discount": 0.75,
          "Extra Type": "O",
          "Room Price": 234,
          "Discount Price": 3.9,
          Markup: 4.68,
          "Net Price": 234.78,
          Currency: "EUR",
          "Offer Discount / 14": {
            SPDO1: 3.9
          },
          "Offer Dest": "SPO5 (SPDO1 5) 3.9",
          cancelPolicy: {
            "0 - 3": 80,
            "4 - 8": 60,
            "9 - 15": 30
          }
        }
      }
    },
    "1": {
      "AIR CONDITION / 1 / O": {
        "1": {
          "Total Price": 372.6,
          "BS Total": 45,
          "Extra Price": 15,
          "Extra Discount": 0.75,
          "Extra Type": "O",
          "Room Price": 372.6,
          "Discount Price": 6.21,
          Markup: 3.73,
          "Net Price": 415.12,
          Currency: "EUR",
          "Offer Discount / 14": {
            SPDO1: 6.21
          },
          "Offer Dest": "SPO5 (SPDO1 5) 6.21",
          cancelPolicy: {
            "0 - 3": 80,
            "4 - 8": 60,
            "9 - 15": 30
          }
        },
        "2": {
          "Total Price": 279,
          "BS Total": 45,
          "Extra Price": 15,
          "Extra Discount": 0.75,
          "Extra Type": "O",
          "Room Price": 279,
          "Discount Price": 4.65,
          Markup: 5.58,
          "Net Price": 324.93,
          Currency: "EUR",
          "Offer Discount / 14": {
            SPDO1: 4.65
          },
          "Offer Dest": "SPO5 (SPDO1 5) 4.65",
          cancelPolicy: {
            "0 - 3": 80,
            "4 - 8": 60,
            "9 - 15": 30
          }
        }
      }
    }
  }
};

const SearchPrice = React.memo(() => {
  const [
    filterSectionVisibilityChange,
    setFilterSectionVisibilityChange
  ] = useState(true);

  const dispatch = useDispatch();

  const [operatorIsEmpty, setOperatorIsEmpty] = useState(true);
  /**************** DATE INPUT VALUE DEFAULT TODAY **************/
  var curr = new Date();
  curr.setDate(curr.getDate());
  var today = curr.toISOString().substring(0, 10);

  /****************************************************************/
  //CREATE PAYLOAD WITH DEFINITION STATE
  const [definitions, setDefinitions] = useState({
    hotel: null,
    region: null,
    country: null,
    operator: null,
    market: null,
    adult: null,
    child: 0,
    date: today,
    stay: null,
    childNumber: null,
    childAges: [],
    currency: {}
  });
  const [allOnSaleContractsHotel, setAllOnSaleContractsHotel] = useState();
  const [
    allOnSaleContractsOperator,
    setAllOnSaleContractsOperator
  ] = useState();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [allOnSaleCONTRACTS, setAllOnSaleCONTRACTS] = useState();
  const [gridData, setGridData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);

  const { operators } = useSelector(({ operators }) => operators);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { markets } = useSelector(({ markets }) => markets);
  const { regions } = useSelector(({ destinations }) => destinations);
  const { allCountries } = useSelector(({ destinations }) => destinations);
  const { authUser } = useSelector(({ auth }) => auth);
  const { contracts } = useSelector(({ contract }) => contract);
  const { currencies } = useSelector(({ currencies }) => currencies);
  const { contract_room } = useSelector(({ contract }) => contract);
  const { boards } = useSelector(({ boards }) => boards);

  console.log(boards);

  useEffect(() => {
    (() => {
      Promise.all([
        dispatch(getOperatorService()),
        dispatch(getMarketService()),
        dispatch(getReservationsService()),
        dispatch(getHotelService()),
        dispatch(getRegionsService()),
        dispatch(getAllCountriesService()),
        dispatch(
          getContractService(
            authUser.authority_level,
            localStorage.getItem("selectedCompany"),
            null,
            null,
            authUser.id
          )
        ),
        dispatch(getCurrenciesService()),
        dispatch(getBoardsService())
      ]);
    })();
  }, []);

  useEffect(() => {
    currencyDataHandler();
  }, [currencies, contracts]);

  const currencyDataHandler = () => {
    if (
      Object.keys(contracts).length > 0 &&
      Object.keys(currencies).length > 0
    ) {
      const currencyIds = Object.values(contracts).map(
        contract => contract.currency
      );

      const arr = currencyIds.map(id => currencies[id]);

      setCurrencyData(arr);
    } else {
      setCurrencyData([]);
    }
  };

  const [isOpen, setIsOpen] = React.useState(true);

  const handleChangePanel = panel => (event, isExpanded) => {
    setIsOpen(isExpanded ? panel : false);
  };

  /********************** ONSALE OLAN CONTRACT HOTELLERİNİN SEÇİLMESİ İÇİN ***********************/
  useEffect(() => {
    axios.get("getallcontractforpricesearch").then(res => {
      setAllOnSaleCONTRACTS(res.data.data);
      //BURADA ONSALE OLAN BÜTÜN KONTRATLARIN HOTELLERİNİ BULUYORUZ.
      let allContracts = res.data.data;
      if (allContracts != undefined && allContracts.length > 0) {
        //AŞAĞIDA HOTELLERİN ID 'Sİ İLE ONSALE CONTRAT HOTELLERİNİN İDLERİNİ EŞİTLEYİP
        //AUTOCOMPLETE'E VERİLİYOR
        const equalHotels = allContracts.map(val => {
          return Object.values(hotels).filter(v => {
            if (
              (val.contract_statuses_id == Number(4) && hotels != undefined) ||
              hotels.length != 0
            ) {
              return Number(v.id) == Number(val.hotel_id);
            }
          });
        });

        let lastResult = equalHotels.flat();

        if (lastResult.length > 0) {
          setAllOnSaleContractsHotel(lastResult);
        }
      }
    });
  }, [hotels]);
  /********************************************************************************************/

  /********************* HOTELE BAĞLI OPERATORLERİN SEÇİLMESİ İÇİN ***************************/

  useEffect(() => {
    let selectedHotel = definitions.hotel;
    if (selectedHotel != null || selectedHotel != "") {
      if (
        allOnSaleContractsHotel != undefined &&
        allOnSaleContractsHotel.length > 0
      ) {
        let filteredSelectedHotel = allOnSaleContractsHotel.find(element => {
          return selectedHotel?.id === element?.id;
        });

        const selectedHotelOperators = allOnSaleCONTRACTS.find(element => {
          if (filteredSelectedHotel?.id == element?.hotel_id) {
            return element.operators;
          }
        });

        function selectedHotelOperatorsValidate() {
          if (selectedHotelOperators !== undefined) {
            return selectedHotelOperators?.operators;
          } else {
            //Database'de table row'larında; selectedHotelOperators datası "[1,2,3,4]"
            //şeklinde tutuluyor.String içine dizi basılmış.Data yapısı enteresan olduğu için
            //aşağıda Json.parse() string içinden dizi çıkarılıyor. selectedHotelOperatorsValidate
            //fonksiyonunun else kısmında şu yapılıyor ----> eğer hiçbir hotel seçilmez ise hotel autocomplete si
            //undefined oluyor ve Json.parse patlıyor.Bunu önlemek için "[]" değeri gönderilmiştir.

            return "[]";
          }
        }
        let opArray = JSON.parse(selectedHotelOperatorsValidate());

        const s = opArray.map(param => {
          return Object.values(operators).filter(val => {
            if (param != undefined && Number(param) == Number(val?.id)) {
              return val.name;
            }
          });
        });

        setAllOnSaleContractsOperator(s.flat());
      }
    }
  }, [definitions.hotel]);

  /*******************************************************************************************/

  /************************* OPERATÖRE BAĞLI MARKETLERİN SEÇİLMESİ İÇİN *********************/
  /******* FIND OPERATOR COUNTRIES WITH USE { definition.operator.id } ****************/
  const operatorCountries =
    definitions?.operator &&
    Object.values(operators).find(
      operator => definitions?.operator.id == operator.id
    ).countries;
  /**********************************************************************************************/
  const marketArr = Object.values(markets);

  /**
   * Market ve operatör db bağlantısı olmadığı için ortak country'lerinden
   * eşleştirme yapmak için aşağıdaki kod bloğu çalışmaktadır.
   * Buradaki kodun nasıl çalıştığını bir Allah bir ben biliyordum.
   * Artık sadece Allah biliyor. Fazla kurcalamayın :))))
   */
  let filteredMarketArray = marketArr.reduce((acc, val) => {
    const hasCountry = val.countries?.find(country =>
      operatorCountries?.find(operatorCountry => operatorCountry.id == country)
    );
    if (hasCountry !== undefined) {
      acc.push(val);
    }
    return acc;
  }, []);

  const test = filteredMarketArray != undefined ? filteredMarketArray : [];
  /***************************************************************************/
  const checkMarketCountry = () => {
    if (operatorIsEmpty === false) {
      if (test.length === 0) {
        Swal.fire(
          `Market and operator countries must be equal. Please check first it at definitions`
        );
      }
    } else {
      Swal.fire(`Please select Operators`);
    }
  };
  /***************************************************************************************************/

  const useStyles = makeStyles(theme => ({
    datagrid: {
      marginTop: 5 + "rem",
      "& .MuiDataGrid-cell": {
        display: "flex!important",
        justifyContent: "center!important",
        alignItems: "center!important",
        flexDirection: "row!important",
        letterSpacing: ".2rem",
        color: "black",
        "&:nth-child(2)": {
          color: "#30919B",
          fontWeight: "bolder",
          fontSize: ".9rem"
        }
      },

      "& .MuiDataGrid-columnHeaderTitleContainer": {
        display: "flex!important",
        justifyContent: "center!important",
        alignItems: "center!important",
        flexDirection: "row!important"
      }
    },
    aut: {
      "&  .MuiAutocomplete-input": {
        color: localStorage.getItem("theme-type") === "dark" ? "white" : "black"
      },
      "&  .MuiInputLabel-root": {
        color:
          localStorage.getItem("theme-type") === "dark"
            ? "gray !important"
            : "black"
      },
      "& .MuiInputLabel-outlined": {
        color:
          localStorage.getItem("theme-type") === "dark"
            ? "gray !important"
            : "black"
      },
      "& .MuiInputBase-input": {
        color:
          localStorage.getItem("theme-type") === "dark"
            ? "white !important"
            : "black"
      }
    }
  }));
  const classes = useStyles();
  const columns = [
    {
      field: "id",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          {" "}
          <ConfirmationNumberIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />{" "}
          &nbsp; ID
        </span>
      ),
      width: 90
    },
    {
      field: "hotel_name",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          {" "}
          <HotelIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />{" "}
          &nbsp; Hotel Name
        </span>
      ),
      width: 270,
      editable: true
    },
    {
      field: "region_name",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          <SouthAmericaIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />
          &nbsp;Region Name
        </span>
      ),
      width: 270,
      editable: true
    },
    {
      field: "country_Name",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          {" "}
          <FlagIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />
          &nbsp;Country name
        </span>
      ),
      width: 270,
      editable: true
    },
    {
      field: "net_price",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          <AttachMoneyIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />
          &nbsp;Net Price
        </span>
      ),
      width: 270,
      editable: true
    },
    {
      field: "discount",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          <DiscountIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />
          &nbsp; Discount
        </span>
      ),
      width: 270,
      editable: true
    },
    {
      field: "total_price",
      headerName: (
        <span style={searchPrice.dataGridTitle}>
          <CardTravelIcon
            style={{
              color: "#30919B",
              fontSize: 1.3 + "rem"
            }}
          />
          &nbsp;Total Price
        </span>
      ),
      width: 270,
      editable: true
    }
  ];

  // DESC - Grid columns
  const gridColumns = [
    {
      field: "hotel",
      headerName: "Hotel",
      flex: 1,
      valueGetter: ({ row }) => hotels[row.hotelId].name
    },
    {
      field: "room",
      headerName: "Room",
      flex: 1,
      valueGetter: ({ row }) => contract_room[row.contractRoomId].room_code
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      valueGetter: ({ row }) => row["Net Price"] + " " + row["Currency"]
    },
    {
      field: "detail",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row }) => {
        console.log(row);
        return (
          <Button
            onClick={() => dialogHandler(row)}
            color="primary"
            variant="contained"
          >
            Detail
          </Button>
        );
      }
    }
  ];

  // DESC - Parse data
  const parseData = data => {
    const result = [];

    if (data) {
      Object.keys(data).forEach(baseKey => {
        const splittedBaseKey = baseKey.split("/");

        // DESC - Set contract & hotel id
        const contractId = parseInt(splittedBaseKey[0]);
        const hotelId = parseInt(splittedBaseKey[1]);

        Object.keys(data[baseKey]).forEach(boardId => {
          // DESC - Set hotel board id
          const hotelBoardId = parseInt(boardId);

          Object.keys(data[baseKey][boardId]).forEach(serviceKey => {
            const splittedServiceKey = serviceKey.split("/");

            // DESC - Set service name, id, isMandatory
            const serviceName = splittedServiceKey[0];
            const serviceId = parseInt(splittedServiceKey[1]);
            const isMandatory = Boolean(parseInt(splittedServiceKey[2]));

            Object.keys(data[baseKey][boardId][serviceKey]).forEach(roomKey => {
              const contractRoomId = parseInt(roomKey);

              const room = data[baseKey][boardId][serviceKey][roomKey];

              const obj = {
                ...room,
                contractId,
                hotelId,
                hotelBoardId,
                serviceName,
                serviceId,
                isMandatory,
                contractRoomId
              };
              result.push(obj);
            });
          });
        });
      });
    }

    setGridData(result);
  };

  /******************HANDLE ALL CHANGES *************************/
  const handleSubmit = async () => {
    //Zorunlu alanlar kontrolü (validasyon) yapılıyor.
    if (
      definitions.adult != null &&
      definitions.adult != 0 &&
      definitions.stay != null &&
      definitions.stay != "" &&
      definitions.date != null &&
      definitions.date != "" &&
      definitions.child > 0 &&
      definitions.child != null &&
      definitions.region != null
    ) {
      let definitionsDate = new Date(definitions.date);

      //Add Stay date to Check in date
      definitionsDate = moment(definitionsDate, "DD-MM-YYYY")
        .add(definitions.stay, "days")
        .format("DD/MM/YYYY");
      definitions.stayEndDate = definitionsDate;

      //DELETE definition.child
      //definition.child property use for create child age modal and child age open/close toggle
      //(with definition.child.length)---> (enter for loop) ----> (create child age input and label)
      delete definitions.childNumber;

      /************************* SEND PAYLOAD TO BACKEND *******************/
      await axios.post("searchprice", definitions).then(res => {
        // console.log(res);
        parseData(res.data);
      });
      /********************************************************************/

      /**************************** RESULT - FILTER COMPONENT VISIBILITY CHANGES **********/
      setFilterSectionVisibilityChange(false);
      setIsOpen(false);
      /************************************************************************************/
    } else {
      //Eğer Zorunlu alanlarda eksik yer var ise Swal.fire dinamik bir şekilde, boş bırakılan alanları
      //yazdırıyor.

      let controlObj = {
        adult: definitions.adult,
        child: definitions.child,
        cDate: definitions.date,
        stay: definitions.stay,
        region: definitions.region
      };
      let alertControl = [];

      //Objeleri aynı bir dizi ile map döner gibi dönmek için aşağıdaki kod bloğu kullanıldı
      for (const key in controlObj) {
        if (controlObj.hasOwnProperty.call(controlObj, key)) {
          const element = controlObj[key];

          if (element == null || element == 0) {
            alertControl.push(key);
          }
        }
      }

      Swal.fire({
        title: "Warning",
        text: `${alertControl.toString().toUpperCase()} can not be empty`,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33"
      });
    }
  };
  const [modalControl, setModalControl] = useState(false);
  const adultNumber = useRef();
  const saveEditButton = useRef();
  const childNumber = useRef();
  const childAgesNumber = useRef([]);
  const [childAgesControl, setChildAgesControl] = useState(true);

  let arr;
  //Çocuk yaşlarını, girilen inputlardan useRef aracılığı ile yakalayıp dizi içerisine obje olarak gönderip
  //Definitions'a ekliyoruz

  arr = childAgesNumber.current.map((param, key) => {
    let obj = {
      id: key,
      age: param?.value
    };

    return obj;
  });

  //Modal kapatıldığı zaman yukarıda inputlardan çekilen çocuk yaşlarını definitions state'ine yüklüyor.
  useEffect(() => {
    modalControl === false &&
      setDefinitions({
        ...definitions,
        childAges: arr
      });
  }, [modalControl]);

  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});

  const dialogHandler = (data = []) => {
    setOpen(!open);
    setDialogData(data);
  };

  return (
    <div style={{ padding: "5px 10px", height: "100vh" }}>
      <Accordion
        sx={searchPrice.accordionStlye}
        expanded={isOpen}
        onChange={handleChangePanel("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ marginLeft: "45%", fontWeight: "bold" }}>
            Price Search Detail
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            sx={{
              marginTop: 3 + "rem"
            }}
            spacing={2}
          >
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={searchPrice.boxStyle}>
                <KeyboardDatePicker
                  // autoOk
                  //variant="inline"
                  inputVariant="outlined"
                  label="* Check In Date"
                  className="dataPickerForLabel forToLabel" //  i was need add class for reach this label and change/add css
                  format="DD/MM/yyyy"
                  //readOnly={true}
                  value={selectedDate}
                  // value={"04/01/2023"}
                  style={{
                    width: 250,
                    margin: "25px auto 5px auto",
                    "&:hover": { background: "gray" }
                  }}
                  onChange={date => {
                    handleDateChange(date);
                    if (date != null && date._d != "Invalid Date") {
                      const dt = date.toISOString().split("T")[0];

                      setDefinitions({
                        ...definitions,
                        date: dt
                      });
                    }
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="* Stay Date"
                  variant="outlined"
                  type="number"
                  sx={{
                    width: 250,
                    margin: "5px auto"
                  }}
                  placeholder="Enter a number"
                  onChange={e => {
                    setDefinitions({
                      ...definitions,
                      stay: e.target.value
                    });
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="* Child number"
                  inputRef={childNumber}
                  variant="outlined"
                  sx={{
                    width: 250,
                    margin: "5px auto"
                  }}
                  type="number"
                  min={0}
                  max={49}
                  placeholder="Enter a number"
                  onChange={e => {
                    if (e.target.value < 0) {
                      setDefinitions({
                        ...definitions,
                        child: Number(0)
                      });
                      Swal.fire("You can not enter negative number");
                      childNumber.current.value = Number(0);
                    }
                    setDefinitions({
                      ...definitions,
                      child: Number(e.target.value)
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        ref={saveEditButton}
                        disabled={definitions.child == 0 ? true : false}
                        sx={{
                          fontSize: ".7rem"
                        }}
                        onClick={() => {
                          setChildAgesControl(!childAgesControl);
                          setModalControl(!modalControl);
                        }}
                        variant="contained"
                      >
                        {modalControl == true && definitions.child > 0
                          ? "Save "
                          : "Edit"}
                      </Button>
                    )
                  }}
                />
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    height: definitions.child > 0 ? "15rem" : 0,
                    //position: "absolute",
                    paddingTop: "1rem",
                    // overflowY: definitions.child > 0 ? "scroll" : "",
                    // marginTop: "24rem",
                    zIndex: "4"
                  }}
                >
                  {definitions.child > 0 && modalControl == true ? (
                    <Accordion
                      sx={{
                        width: "20rem",
                        position: "absolute",
                        marginTop:
                          definitions.child > 0 ? "15rem!important" : "",
                        height: definitions.child > 0 ? "15rem" : "",
                        overflowY: definitions.child > 0 ? "scroll" : ""
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> Child</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {[...Array(definitions.child).keys()].map(
                          (val, key) => (
                            // <Accordion
                            //   sx={{
                            //     width: "20rem",
                            //   }}
                            // >
                            //   <AccordionSummary
                            //     expandIcon={<ExpandMoreIcon />}
                            //     aria-controls="panel1a-content"
                            //     id="panel1a-header"
                            //   >
                            //     <Typography>{val + 1}. Child</Typography>
                            //   </AccordionSummary>
                            //   <AccordionDetails>

                            <TextField
                              id="outlined-basic"
                              label={`${val + 1}. Child Age`}
                              inputRef={el =>
                                (childAgesNumber.current[key] = el)
                              }
                              variant="outlined"
                              sx={{
                                width: "100%",
                                margin: "5px auto"
                              }}
                              type="number"
                              defaultValue={
                                definitions.childAges[key]?.age
                                  ? definitions.childAges[key]?.age
                                  : 0
                              }
                              onChange={e => {
                                if (Number(e.target.value) >= 19) {
                                  Swal.fire(
                                    "Child age cannot be older than 18"
                                  );
                                  childAgesNumber.current[key].value = 0;
                                }
                              }}
                            />
                            //   </AccordionDetails>
                            // </Accordion>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    ""
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={searchPrice.boxStyle}>
                <TextField
                  className={classes.aut}
                  id="outlined-basic"
                  label="* Adult number"
                  inputRef={adultNumber}
                  variant="outlined"
                  sx={{
                    width: 250,
                    margin: "5px auto"
                  }}
                  type="number"
                  placeholder="Enter a number"
                  onChange={e => {
                    let x = e.target.value;

                    if (x < 0) {
                      setDefinitions({
                        ...definitions,
                        adult: Number(0)
                      });
                      Swal.fire("You can not enter negative number");
                      adultNumber.current.value = Number(0);
                    }
                    setDefinitions({
                      ...definitions,
                      adult: Number(e.target.value)
                    });
                  }}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allOnSaleContractsHotel != undefined
                      ? allOnSaleContractsHotel.map(param => ({
                          id: param.id,
                          label: param.name
                        }))
                      : []
                  }
                  className={classes.aut}
                  onChange={(_, value) =>
                    setDefinitions({
                      ...definitions,
                      hotel: value
                    })
                  }
                  sx={searchPrice.autoComplete}
                  renderInput={params => (
                    <TextField {...params} label="Hotel" />
                  )}
                />
                <Autocomplete
                  style={searchPrice.autoComplete}
                  className={classes.aut}
                  disablePortal
                  id="combo-box-demo"
                  options={regions.map(param => ({
                    id: param.id,
                    label: param.name
                  }))}
                  sx={searchPrice.autoComplete}
                  renderInput={params => (
                    <TextField {...params} label="* Region" />
                  )}
                  onChange={(_, value) =>
                    setDefinitions({
                      ...definitions,
                      region: value
                    })
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={searchPrice.boxStyle}>
                <Autocomplete
                  style={searchPrice.autoComplete}
                  className={classes.aut}
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allOnSaleContractsOperator != undefined &&
                    allOnSaleContractsOperator.length > 0
                      ? allOnSaleContractsOperator.map(param => ({
                          id: param.id,
                          label: param.name
                        }))
                      : Object.values(operators).map(param => ({
                          id: param.id,
                          label: param.name
                        }))
                  }
                  sx={searchPrice.autoComplete}
                  renderInput={params => (
                    <TextField {...params} label="Operator" />
                  )}
                  onChange={(e, value) => {
                    setOperatorIsEmpty(false);
                    setDefinitions({
                      ...definitions,
                      operator: value
                    });
                  }}
                />
                <Autocomplete
                  style={searchPrice.autoComplete}
                  className={classes.aut}
                  disablePortal
                  onHighlightChange={() => checkMarketCountry()}
                  id="combo-box-demo"
                  options={
                    operatorCountries != null
                      ? test?.map(param => ({
                          id: param?.id,
                          label: param?.name,
                          code: param?.code
                        }))
                      : []
                  }
                  sx={searchPrice.autoComplete}
                  renderInput={params => (
                    <TextField {...params} label="Market" />
                  )}
                  onChange={(e, value) =>
                    setDefinitions({
                      ...definitions,
                      market: value
                    })
                  }
                />

                <Autocomplete
                  style={searchPrice.autoComplete}
                  className={classes.aut}
                  disablePortal
                  id="combo-box-demo"
                  options={allCountries.map(param => ({
                    id: param.id,
                    label: param.name
                  }))}
                  sx={searchPrice.autoComplete}
                  renderInput={params => (
                    <TextField {...params} label="Country" />
                  )}
                  onChange={(e, value) =>
                    setDefinitions({
                      ...definitions,
                      country: value
                    })
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={searchPrice.boxStyle}>
                <Autocomplete
                  style={searchPrice.autoComplete}
                  className={classes.aut}
                  disablePortal
                  id="combo-box-demo"
                  options={currencyData}
                  sx={searchPrice.autoComplete}
                  getOptionLabel={option => option.code}
                  renderInput={params => (
                    <TextField {...params} label="Currency" />
                  )}
                  onChange={(e, value) => {
                    setOperatorIsEmpty(false);
                    setDefinitions({
                      ...definitions,
                      currency: value
                    });
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button onClick={handleSubmit} variant="contained">
                Search
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {!filterSectionVisibilityChange && (
        <div
          style={{
            width: "100%",
            height: "600px",
            margin: "10px 0"
          }}
        >
          <DataGrid
            rows={gridData}
            columns={gridColumns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={() => Math.random() * 99999}
            checkboxSelection
            disableSelectionOnClick
            style={{ height: "100%" }}
          />
        </div>
      )}

      <Dialog
        onClose={dialogHandler}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="simple-dialog-title">
          {contract_room[dialogData.contractRoomId]?.agency_room_name} Details
        </DialogTitle>
        <DialogContent>
          {console.log(dialogData)}
          <p>Board: {boards[dialogData.hotelBoardId]?.name}</p>
          <p>
            Service:{" "}
            {!dialogData.isMandatory
              ? dialogData.serviceName +
                " " +
                (dialogData["Net Price"] +
                  dialogData["Extra Price"] -
                  dialogData["Extra Discount"])
              : dialogData.serviceName}
          </p>
          <p>Base Price: {dialogData["Total Price"]}</p>
          {dialogData["BS Total"] !== 0 && (
            <p>Board Supplement: {dialogData["BS Total"]}</p>
          )}
          <h6>Offers</h6>
          {Object.keys(dialogData).map(key => {
            if (key.includes("Offer Discount")) {
              return Object.keys(dialogData[key]).map(offerName => {
                return (
                  <p>
                    {offerName}: {dialogData[key][offerName]}
                  </p>
                );
              });
            }
          })}
          <p>Total Discount: {dialogData["Discount Price"]}</p>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default SearchPrice;
