import { FormControl, TextField } from "@material-ui/core";
import MaterialTable, { MTableEditRow } from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useIntl } from "react-intl";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "@jumbo/components/Common/Loader";
const MySwal = withReactContent(Swal);

const customerAgeCalculator = (birthday, start_date) => {
  const MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365;
  // birthday = Date.parse(birthday.getDate() + '-' + parseInt(birthday.getMonth() + 1) + '-' + birthday.getFullYear());
  const age = parseInt(
    (Date.parse(start_date) - Date.parse(birthday)) / MS_PER_YEAR
  );
  return age;
};

const CustomerTable = props => {
  // Seçiliş olan Tabın customerlarını gösterebilmek için yapılmış bir işlem
  const roomNo = props.index;
  const [customers, setCustomers] = useState(props.customers);
  const rooms = Object.values(props.reservationDetail.rooms);
  const [roomLimits, setRoomLimits] = useState(rooms[roomNo]);
  const [roomCustomersCount, setRoomCustomersCount] = useState({
    adult: 0,
    child: 0,
    infant: 0
  });
  const intl = useIntl();

  const [columns, setColumns] = useState([
    {
      title: intl.formatMessage({ id: "gender" }),
      field: "gender",
      align: "center",
      lookup: {
        Adult: "adult",
        Child: "child",
        Infant: "infant"
      }
    },
    {
      title: intl.formatMessage({
        id: "name"
      }),
      field: "name",
      align: "center"
    },
    {
      title: intl.formatMessage({
        id: "surname"
      }),
      field: "surname",
      align: "center"
    },
    {
      title: intl.formatMessage({ id: "date.of.birth" }),
      field: "birthday",
      align: "center",
      type: "date",
      render: rowData => {
        return moment(rowData.birthday).format("DD/MM/YYYY");
      }
    },
    {
      title: intl.formatMessage({
        id: "age"
      }),
      field: "age",
      align: "center",
      editable: "never"
    }
  ]);
  useEffect(() => {
    setCustomers(props.customers);
    setRoomLimits(rooms[roomNo]);
    setRoomCustomersCount({
      adult:
        props.customers !== undefined
          ? props.customers.filter(customer => customer.gender == "adult")
              .length
          : 0,
      child:
        props.customers !== undefined
          ? props.customers.filter(customer => customer.gender == "child")
              .length
          : 0,
      infant:
        props.customers !== undefined
          ? props.customers.filter(customer => customer.gender == "infant")
              .length
          : 0
    });

    setColumns([
      {
        title: intl.formatMessage({
          id: "pax"
        }),
        field: "gender",
        align: "center",
        validate: rowData => (rowData.gender ? true : false),

        lookup: { adult: "Adult", child: "Child", infant: "Infant" }
      },

      {
        title: intl.formatMessage({
          id: "name"
        }),
        field: "name",
        align: "center",
        validate: rowData => (rowData.name ? true : false)
      },
      {
        title: intl.formatMessage({
          id: "surname"
        }),
        field: "surname",
        align: "center",
        validate: rowData => (rowData.surname ? true : false)
      },
      {
        title: intl.formatMessage({
          id: "date.of.birth"
        }),
        field: "birthday",
        render: rowData => {
          return moment(rowData.birthday).format("DD/MM/YYYY");
        },
        type: intl.formatMessage({
          id: "date"
        }),
        align: "center",
        editComponent: props => (
          <FormControl>
            {/* <TextField
              id="birthday"
              type="date"
              defaultValue="YYYY-MM-DD"
              InputLabelProps={{
                shrink: true
              }}
              value={props.birthday}
              onChange={(e) => props.onChange(e.target.value)}
            /> */}
            <KeyboardDatePicker
              name="birtday"
              autoOk="true"
              format="DD/MM/YYYY"
              minDate="1924/01/01"
              value={
                props.value
                  ? moment(props.value).format("YYYY-MM-DD")
                  : moment(props.birthday).format("YYYY-MM-DD")
              }
              onChange={(date, value) =>
                props.onChange(moment(date).format("YYYY-MM-DD"))
              }
              animateYearScrolling
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
        )
      },
      {
        title: intl.formatMessage({
          id: "age"
        }),
        field: "age",
        align: "center",
        editable: "never"
      }
    ]);
  }, [props.customers]);

  return (
    <MaterialTable
      columns={columns}
      data={customers}
      icons={{
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Clear: () => <ModeCloseIcon sx={{ color: "red" }} />
      }}
      title={intl.formatMessage({ id: "customers.details" })}
      options={{
        pageSize: 5,
        pageSizeOptions: [5, 10, 20, 30],
        showTextRowsSelected: false,
        search: false,
        actionsColumnIndex: -1,
        addRowPosition: "first"
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const updatedCustomers = rooms[roomNo].customers;
              if (newData.birthday !== "") {
                const customerAge = customerAgeCalculator(
                  newData.birthday,
                  props.reservationDetail.start_date
                );
                if (
                  props.selectedContract !== null
                    ? (newData.gender === "adult" &&
                        customerAge > props.selectedContract.max_child_age) ||
                      (newData.gender === "child" &&
                        customerAge < props.selectedContract.max_child_age &&
                        customerAge >= props.selectedContract.max_infant_age) ||
                      (newData.gender === "infant" &&
                        customerAge < props.selectedContract.max_infant_age &&
                        customerAge >= 0)
                    : (newData.gender === "adult" && customerAge >= 18) ||
                      (newData.gender === "child" &&
                        customerAge < 18 &&
                        customerAge >= 2) ||
                      (newData.gender === "infant" &&
                        customerAge < 2 &&
                        customerAge >= 0)
                ) {
                  if (
                    (newData.gender === "adult" &&
                      roomLimits.adult > roomCustomersCount.adult) ||
                    (newData.gender === "child" &&
                      roomLimits.child > roomCustomersCount.child) ||
                    (newData.gender === "infant" &&
                      roomLimits.infant > roomCustomersCount.infant) ||
                    newData.gender === oldData.gender
                  ) {
                    newData.age = customerAge;
                    const dataCopy = [...customers];
                    // Find the index of the updated row - we have to use old data since
                    // new data is not part of state yet
                    const index = dataCopy.indexOf(oldData);
                    // Update the found index with the new data
                    dataCopy[index] = newData;
                    // Update our state
                    setCustomers(dataCopy);
                    updatedCustomers[oldData.tableData.id] = { ...newData };
                    props.customerAdd(prev => ({
                      ...prev,
                      rooms: {
                        ...prev.rooms,
                        [rooms[roomNo].id]: {
                          ...prev.rooms[rooms[roomNo].id],
                          customers: updatedCustomers
                        }
                      }
                    }));
                    if (newData.gender === "adult") {
                      setRoomCustomersCount({
                        ...roomCustomersCount,
                        adult: roomCustomersCount.adult + 1
                      });
                    } else if (newData.gender === "child") {
                      setRoomCustomersCount({
                        ...roomCustomersCount,
                        child: roomCustomersCount.child + 1
                      });
                    } else if (newData.gender === "infant") {
                      setRoomCustomersCount({
                        ...roomCustomersCount,
                        infant: roomCustomersCount.infant + 1
                      });
                    }
                    resolve();
                  } else {
                    MySwal.fire({
                      icon: "error",
                      title: intl.formatMessage({
                        id: "limit.reached"
                      }),
                      text: intl.formatMessage({
                        id: "Limit reached for this room"
                      }),
                      timerProgressBar: true
                    });
                    reject();
                  }
                } else {
                  MySwal.fire({
                    icon: "error",
                    title: "Age disagrement",
                    text: `${newData.gender.toUpperCase()} Not age matched for this gender! `,
                    timerProgressBar: true
                  });
                  reject();
                }
              } else {
                MySwal.fire({
                  icon: "error",
                  title: intl.formatMessage({
                    id: "date.of.birth.required"
                  }),
                  text: intl.formatMessage({
                    id: "please.enter.date.of.birth"
                  }),
                  timerProgressBar: true
                });
                reject();
              }
            }, 1000);
          })
      }}
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: props => {
          return (
            <MTableEditRow
              {...props}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == "update") {
                      Swal.fire({
                        title: intl.formatMessage({
                          id: "are.you.sure?"
                        }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "add") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          );
        }
      }}
    />
  );
};

export default CustomerTable;
