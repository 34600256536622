import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import BigCalendar from "react-big-calendar-like-google";
import { Container, Row, Col } from "react-grid-system";
import moment from "moment";
import AppSelectBox from "../../../@jumbo/components/Common/formElements/AppSelectBox";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getStopSaleService } from "services/stopSale";
import { getContractService } from "services/contract";
import { getRoomService } from "services/rooms";
import { getHotelService } from "services/hotels";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getOpenSaleService } from "services/openSale";
import "react-big-calendar-like-google/lib/css/react-big-calendar.css";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { Autocomplete } from "@material-ui/lab";
import { useIntl } from "react-intl";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  selectBoxRoot: {
    marginBottom: 20,
    "& .MuiOutlinedInput-input": {
      backgroundColor: theme.palette.background.paper
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400]
    }
  },
  button: {
    height: "40px !important"
  }
}));
const CulturesCalendar = () => {
  const classes = useStyles();
  const [hotel, setHotel] = useState();
  const intl = useIntl();
  const [contracts, setContract] = useState();
  const [room, setRoom] = useState();
  const [sales, setSales] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [filterState, setFilterState] = useState({
    hotel_id: "",
    contract_id: "",
    room_id: "",
    startDate: "",
    endDate: ""
  });
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text
    });
  };
  BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
  const { authUser } = useSelector(({ auth }) => auth);
  const { selectedCompany } = useSelector(({ auth }) => auth);

  useEffect(async () => {
    await dispatch(
      getContractService(
        authUser.authority_level,
        localStorage.getItem("selectedCompany"),
        null,
        null,
        authUser.id
      )
    );
    await dispatch(getOpenSaleService());
    await dispatch(getStopSaleService());
    await dispatch(getHotelService());
    await dispatch(getRoomService());
  }, [dispatch]);

  const dispatch = useDispatch();
  const openS = useSelector(state => state.openSale.openSale);
  const stopS = useSelector(state => state.stopSale.stopSale);
  const contract = useSelector(state => state.contract.contracts);
  const contractRoom = useSelector(state => state.contract.contract_room);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const {
    rooms: { roomType }
  } = useSelector(({ rooms }) => rooms);
  const selectContract = [];
  const selectHotel = [];
  var selectRoom = [];
  Object.values(hotels).map(h => {
    selectHotel.push({ id: h.id, name: h.name });
  });
  Object.keys(contract).map(a => {
    if (filterState.hotel_id == contract[a].hotel_id) {
      selectContract.push({
        id: contract[a].id,
        name: contract[a].contract_name
      });
    }
  });
  var cRoomId = contract[filterState.contract_id]?.contract_room;
  if (cRoomId) {
    cRoomId.map(item => {
      var roomId = contractRoom[item].room_id;
      selectRoom = [
        ...selectRoom,
        { id: roomType[roomId].id, name: roomType[roomId].room_name }
      ];
    });
  }
  const roomTypeControl = sale => {
    let sale_room_codes = sale.room_codes.split(",");
    if (sale_room_codes.length > 1) {
      return sale_room_codes.filter(
        room => room == roomType[filterState.room_id].room_name
      ).length > 0
        ? true
        : false;
    } else {
      return sale_room_codes[0] == roomType[filterState.room_id]?.room_name
        ? true
        : false;
    }
  };
  const filterHandleChange = e => {
    const { name, value } = e.target;
    if (value !== "Invalid date") {
      setFilterState(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };
  const filterSelected = () => {
    if (
      filterState.contract_id !== "" &&
      filterState.room_id !== "" &&
      filterState.hotel_id !== ""
    ) {
      if (filterState.endDate !== "" && filterState.startDate !== "") {
        let newSales = [];
        let filteredStops = stopS.filter(
          stop =>
            filterState.startDate <= stop.start_date &&
            filterState.endDate >= stop.end_date &&
            Number(filterState.hotel_id) == stop.hotel_id &&
            roomTypeControl(stop)
        );
        let filteredOpens = openS.filter(
          open =>
            filterState.startDate <= open.start_date &&
            filterState.endDate >= open.end_date &&
            Number(filterState.hotel_id) == open.hotel_id &&
            roomTypeControl(open)
        );
        filteredOpens.map(open => {
          let openTitle =
            "Room Codes: " +
            open.roomString +
            " Hotel Name: " +
            hotels[filterState.hotel_id].name;
          newSales.push({
            title: openTitle,
            bgColor: "rgb(82 177 85)",
            start: open.start_date,
            end: open.end_date,
            allDay: true,
            type: "Open Sale"
          });
        });
        filteredStops.map(stop => {
          let stopTitle =
            "Room Codes: " +
            stop.roomString +
            " Hotel Name: " +
            hotels[filterState.hotel_id].name;
          newSales.push({
            title: stopTitle,
            bgColor: "rgb(181 54 54)",
            start: stop.start_date,
            end: stop.end_date,
            allDay: true,
            type: "Stop Sale"
          });
        });
        setSales(newSales);
        setSelectedActions({
          filteredStops: filteredStops,
          filteredOpens: filteredOpens
        });
      } else {
        sweetAlerts("warning", "Please Select Start & End Date !");
      }
    } else {
      sweetAlerts("warning", "Please Enter Empty Fields !");
    }
  };
  return (
    <PageContainer>
      <Container>
        <div className={classes.selectBoxRoot}>
          <Row xs="fixed">
            <Col item sm={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  name="startDate"
                  disablePast
                  autoComplete="off"
                  autoOk="true"
                  label={<IntlMessages id="start.date" />}
                  value={
                    filterState.startDate == ""
                      ? moment(new Date()).format("YYYY-MM-DD")
                      : moment(filterState.startDate).format("YYYY-MM-DD")
                  }
                  onChange={date => {
                    let e = {
                      target: {
                        name: "startDate",
                        value: moment(date).format("YYYY-MM-DD")
                      }
                    };
                    filterHandleChange(e);
                  }}
                  format="dd/MM/yyyy"
                  animateYearScrolling
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </MuiPickersUtilsProvider>
            </Col>
            <Col item sm={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  name="endDate"
                  disablePast
                  format="dd/MM/yyyy"
                  autoComplete="off"
                  autoOk="true"
                  minDate={filterState.startDate}
                  label={<IntlMessages id="end.date" />}
                  value={
                    filterState.endDate == ""
                      ? moment(new Date()).format("YYYY-MM-DD")
                      : moment(filterState.endDate).format("YYYY-MM-DD")
                  }
                  onChange={date => {
                    let e = {
                      target: {
                        name: "endDate",
                        value: moment(date).format("YYYY-MM-DD")
                      }
                    };
                    filterHandleChange(e);
                  }}
                  animateYearScrolling
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </MuiPickersUtilsProvider>
            </Col>
            <Col item sm={2}>
              <Autocomplete
                options={selectHotel}
                onChange={(e, value) => {
                  let event = {
                    target: {
                      name: "hotel_id",
                      value: value != null ? value.id : ""
                    }
                  };
                  filterHandleChange(event);
                }}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    error={filterState.hotel_id !== "" ? false : true}
                    placeholder={intl.formatMessage({ id: "select.product" })}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Col>
            <Col item sm={2}>
              <Autocomplete
                options={selectContract}
                onChange={(e, value) => {
                  let event = {
                    target: {
                      name: "contract_id",
                      value: value != null ? value.id : ""
                    }
                  };
                  filterHandleChange(event);
                }}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    error={filterState.contract_id !== "" ? false : true}
                    placeholder={intl.formatMessage({ id: "select.contract" })}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Col>
            <Col item sm={2}>
              <Autocomplete
                options={selectRoom}
                onChange={(e, value) => {
                  let event = {
                    target: {
                      name: "room_id",
                      value: value != null ? value.id : ""
                    }
                  };
                  filterHandleChange(event);
                }}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    error={filterState.room_id !== "" ? false : true}
                    placeholder={intl.formatMessage({ id: "select.room" })}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Col>
            <Col item sm={2}>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => filterSelected()}
              >
                <IntlMessages id="filter" />
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      <div>
        <BigCalendar
          events={sales}
          defaultView="month"
          date={
            filterState.startDate ? new Date(filterState.startDate) : new Date()
          }
          onNavigate={date => {
            setFilterState(prev => {
              return { ...prev, startDate: date, endDate: date };
            });
          }}
        />
      </div>
    </PageContainer>
  );
};

export default CulturesCalendar;
