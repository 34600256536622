const theme = localStorage.getItem("theme-type");

const searchPrice = {
  boxStyle: {
    width: 100 + "%",
    height: 11 + "rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    "& .forToLabel > label.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      padding: "5px",
      position: "absolute",
      top: "-5px",
      background: "#ffffff", // #363636 at dark mode
      lineHeight: "10px"
    }

    // "&:hover": {
    //   backgroundColor: "primary.main",
    //   opacity: [0.9, 0.8, 0.7],
    // },
  },

  accordionStlye: {
    backgroundColor: theme === "dark" ? "#363636" : "",
    border: theme === "dark" ? "1px solid rgba(255, 255, 255, 0.08)" : "",
    color: theme === "dark" ? "white !important" : "black !important",
    "& .MuiOutlinedInput-input": {
      color: theme === "dark" ? "white !important" : "black !important"
    },
    "&  .MuiInputLabel-root": {
      color: theme === "dark" ? "gray !important" : "black"
    },
    "& .MuiInputLabel-outlined": {
      color: theme === "dark" ? "gray !important" : "black"
    }
  },
  autoComplete: {
    width: 250,
    margin: "5px auto",
    backgroundColor: theme === "dark" ? "#363636" : "",
    border:
      theme === "dark" ? "1px solid rgba(255, 255, 255, 0.08) !important" : "",
    color: theme === "dark" ? "white !important" : "black !important"
  }
};

export default searchPrice;
