import IntlMessages from "@jumbo/utils/IntlMessages";
import { FormControl, TextField } from "@material-ui/core";
import MaterialTable, { MTableEditRow } from "material-table";
import React, { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "@jumbo/components/Common/Loader";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";

const MySwal = withReactContent(Swal);

const customerAgeCalculator = (birthday, start_date) => {
  const MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365;
  // birthday = Date.parse(birthday.getDate() + '-' + parseInt(birthday.getMonth() + 1) + '-' + birthday.getFullYear());
  const age = parseInt(
    (Date.parse(start_date) - Date.parse(birthday)) / MS_PER_YEAR
  );
  return age;
};

export default function CustomerTableUpdate({
  room,
  roomNo,
  customers,
  setReservationRooms,
  selectedContract,
  reservation,
  type
}) {
  const [roomCustomersCount, setRoomCustomersCount] = useState({
    adult:
      customers !== undefined
        ? customers.filter(customer => customer.gender === "adult").length
        : 0,
    child:
      customers !== undefined
        ? customers.filter(customer => customer.gender === "child").length
        : 0,
    infant:
      customers !== undefined
        ? customers.filter(customer => customer.gender === "infant").length
        : 0
  });
  const intl = useIntl();

  const [roomLimits, setRoomLimits] = useState(room);

  const [columns, setColumns] = useState([
    {
      title: <IntlMessages id="pax" />,
      field: "gender",
      align: "center",
      lookup: { adult: "Adult", child: "Child", infant: "Infant" }
    },
    { title: <IntlMessages id="name" />, field: "name", align: "center" },
    { title: <IntlMessages id="surname" />, field: "surname", align: "center" },
    {
      title: intl.formatMessage({
        id: "date.of.birth"
      }),
      field: "birthday",
      render: rowData => {
        return moment(rowData.birthday).format("DD/MM/YYYY");
      },
      type: intl.formatMessage({
        id: "date"
      }),
      align: "center",
      editComponent: props => (
        <FormControl>
          <KeyboardDatePicker
            name="birtday"
            autoOk="true"
            format="DD/MM/YYYY"
            minDate="1924/01/01"
            value={
              props.value
                ? moment(props.value).format("YYYY-MM-DD")
                : moment(props.birthday).format("YYYY-MM-DD")
            }
            onChange={(date, value) =>
              props.onChange(moment(date).format("YYYY-MM-DD"))
            }
            animateYearScrolling
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
      )
    },
    {
      title: <IntlMessages id="age" />,
      field: "age",
      align: "center",
      editable: "never"
    }
  ]);

  useEffect(() => {
    setRoomCustomersCount({
      adult:
        customers !== undefined
          ? customers.filter(customer => customer.gender === "adult").length
          : 0,
      child:
        customers !== undefined
          ? customers.filter(customer => customer.gender === "child").length
          : 0,
      infant:
        customers !== undefined
          ? customers.filter(customer => customer.gender === "infant").length
          : 0
    });
    setRoomLimits(room);
  }, [room, customers]);
  const tab = useRef();
  useEffect(() => {
    let mat = tab.current.props.data;

    mat.map(val => {
      if (val.tableData.hasOwnProperty("editing")) {
        val.tableData.editing = undefined;
      }
    });
  });
  return (
    <MaterialTable
      tableRef={tab}
      columns={columns}
      data={customers}
      icons={{
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Clear: () => <ModeCloseIcon sx={{ color: "red" }} />
      }}
      title={<IntlMessages id="customers.details" />}
      options={{
        pageSize: 3,
        pageSizeOptions: [10, 20, 30],
        showTextRowsSelected: false,
        search: false,
        actionsColumnIndex: 0,
        addRowPosition: "first"
      }}
      editable={
        !type
          ? {
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (newData.birthday !== "") {
                      const customerAge = customerAgeCalculator(
                        newData.birthday,
                        reservation.start_date
                      );
                      if (
                        selectedContract !== undefined
                          ? (newData.gender === "adult" &&
                              customerAge > selectedContract.max_child_age) ||
                            (newData.gender === "child" &&
                              customerAge < selectedContract.max_child_age &&
                              customerAge >= selectedContract.max_infant_age) ||
                            (newData.gender === "infant" &&
                              customerAge < selectedContract.max_infant_age &&
                              customerAge >= 0)
                          : (newData.gender === "adult" && customerAge >= 18) ||
                            (newData.gender === "child" &&
                              customerAge < 18 &&
                              customerAge >= 2) ||
                            (newData.gender === "infant" &&
                              customerAge < 2 &&
                              customerAge >= 0)
                      ) {
                        if (
                          (newData.gender === "adult" &&
                            roomLimits.adult > roomCustomersCount.adult) ||
                          (newData.gender === "child" &&
                            roomLimits.child > roomCustomersCount.child) ||
                          (newData.gender === "infant" &&
                            roomLimits.infant > roomCustomersCount.infant) ||
                          newData.gender === oldData.gender
                        ) {
                          newData.age = customerAge;
                          const dataCopy = [...customers];
                          // Find the index of the updated row - we have to use old data since
                          // new data is not part of state yet
                          const index = dataCopy.indexOf(oldData);
                          // Update the found index with the new data
                          dataCopy[index] = newData;
                          // Update our state
                          //setCustomers(dataCopy);
                          //updatedCustomers[oldData.tableData.id] = { ...newData };
                          setReservationRooms(prev => ({
                            ...prev,
                            [roomNo]: {
                              ...prev[roomNo],
                              customers: dataCopy
                            }
                          }));
                          if (newData.gender === "adult") {
                            setRoomCustomersCount({
                              ...roomCustomersCount,
                              adult: roomCustomersCount.adult + 1
                            });
                          } else if (newData.gender === "child") {
                            setRoomCustomersCount({
                              ...roomCustomersCount,
                              child: roomCustomersCount.child + 1
                            });
                          } else if (newData.gender === "infant") {
                            setRoomCustomersCount({
                              ...roomCustomersCount,
                              infant: roomCustomersCount.infant + 1
                            });
                          }
                          resolve();
                        } else {
                          MySwal.fire({
                            icon: "error",
                            title: intl.formatMessage({ id: "limit.reached" }),
                            text: intl.formatMessage({
                              id: "limit.reached.for.this.selection"
                            }),
                            timer: 2500,
                            timerProgressBar: true
                          });
                          reject();
                        }
                      } else {
                        MySwal.fire({
                          icon: "error",
                          title: intl.formatMessage({ id: "age.disagrement" }),
                          text:
                            `${newData.gender.toUpperCase()} ` +
                            intl.formatMessage({
                              id: "not.age.matched.for.this.gender"
                            }),
                          timer: 1500,
                          timerProgressBar: true
                        });
                        reject();
                      }
                    } else {
                      MySwal.fire({
                        icon: "error",
                        title: intl.formatMessage({
                          id: "date.of.birth.required"
                        }),
                        text: intl.formatMessage({
                          id: "please.input.date.of.birth"
                        }),
                        timer: 1500,
                        timerProgressBar: true
                      });
                      reject();
                    }
                  }, 1000);
                })
            }
          : {}
      }
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: props => {
          return (
            <MTableEditRow
              {...props}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == "update") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "add") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          );
        }
      }}
    />
  );
}
