import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";

import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

import React, { createRef, useEffect, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import { deleteReservationService } from "services/reservation";

import ReservationUpdate from "./ReservationUpdate";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  CardContent
} from "@material-ui/core";
import { getReservationPeriod } from "services/period";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import { DatePicker, Space } from "antd";
import Swal from "sweetalert2";
import IntlMessages from "@jumbo/utils/IntlMessages";
import { useIntl } from "react-intl";
import ActionsMenu from "./ActionsMenu";
import CmtCard from "@coremat/CmtCard";
import { Container, Row } from "react-grid-system";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

export default function ShowReservation() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const reservationIDs = useSelector(state => state.reservation.result);
  const reservations = useSelector(state => state.reservation?.entities?.reservations); //prettier-ignore
  const operators = useSelector(state => state.operators.operators);
  const hotels = useSelector(state => state.hotels.hotels);
  const markets_selector = useSelector(state => state.markets.markets);
  //const roomTypes = useSelector(state => state.rooms.roomType);
  const boards = useSelector(state => state.boards.boards);
  const currencies = useSelector(state => state.currencies.currencies);
  const contracts_selector = Object.values(
    useSelector(state => state.contract.contracts)
  );
  const [reservationsData, setReservationsData] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);

  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);

  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const { contracts } = useSelector(({ contract }) => contract);
  const { contract_room } = useSelector(({ contract }) => contract);
  const reservation_rooms = useSelector(
    state => state.reservation?.entities?.rooms
  );
  const { markets } = useSelector(({ markets }) => markets);
  const { contract_has_board } = useSelector(({ contract }) => contract);
  const dateFormat = "YYYY/MM/DD";
  let isAuthUpdate = permissionsByAuthUser.some(
    permission => permission.route_id == 4 && permission.put === 1
  );
  const [pageSize, setPageSize] = React.useState(5);

  const handleBookingStatus = booking_status => {
    console.log(booking_status);
    switch (booking_status) {
      case "1":
        return "New";
      case "2":
        return "Updated";
      case "3":
        return "Cancel";
      case "4":
        return "No Show ";
    }
  };

  const handleCommicationStatus = communication_status => {
    switch (communication_status) {
      case 1:
        return " Pending Sending ";
      case 2:
        return " Sent ";
    }
  };

  const handleHotelStatus = hotel_status => {
    switch (hotel_status) {
      case 1:
        return "Not Sent";
      case 2:
        return "Waiting";
      case 3:
        return "Cancelled";
      case 4:
        return "Confirmed";
    }
  };

  const handleReleaseDialogOpen = () => {
    setReleaseDialogOpen(true);
  };
  const handleReleaseDialogClose = () => {
    setReleaseDialogOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setType(false);
  };

  const handleDelete = rowData => {
    dispatch(deleteReservationService(rowData.id));
    setReleaseDialogOpen(false);
    Swal.fire({
      title: "Deleted!",
      text: "Reservation has been deleted.",
      icon: "success"
    });
  };

  useEffect(() => {
    if (reservations !== undefined) {
      setReservationsData(Object.values(reservations));
    }
  }, [reservationIDs, reservations]);
  const dateToConvert = date => {
    var temp = "";
    temp = `${`0${new Date(date).getDate()}`.slice(-2)}/${`0${new Date(
      date
    ).getMonth() + 1}`.slice(-2)}/${new Date(date).getFullYear()}`;
    return temp;
  };
  /**************FILTER SECTION*********************/

  const [filterState, setFilterState] = useState({
    hotel_id: "",
    operator_id: "",
    voucher_no: "",
    hotel_status: "",
    user_id: "",
    selling_date: "",
    start_date: "",
    end_date: ""
  });
  const {
    rooms: { roomType }
  } = useSelector(({ rooms }) => rooms);
  const [hotelStatus, setHotelStatus] = useState([
    { name: "NOT SENT", id: 1 },
    { name: "WAITING", id: 2 },
    { name: "CANCELLED", id: 3 },
    { name: "CONFIRMED", id: 4 }
  ]);
  const { RangePicker } = DatePicker;

  const filterHandleChange = e => {
    const { name, value } = e;
    setFilterState(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const handleChangeStart = (name, value) => {
    setFilterState({
      ...filterState,
      start_date: { from: value[0], to: value[1] }
    });
  };
  const handleChangeEnd = (name, value) => {
    setFilterState({
      ...filterState,
      end_date: { from: value[0], to: value[1] }
    });
  };
  const handleChangeSelling = (name, value) => {
    setFilterState({
      ...filterState,
      selling_date: { from: value[0], to: value[1] }
    });
  };
  const filterSelected = () => {
    if (reservations !== undefined || null) {
      let allReservations = Object.values(reservations)?.map(reservation => {
        let resHotel = hotels[reservation.hotel_id];
        let newReservation = {
          ...reservation,
          hotelStatus:
            reservation.hotel_status === 1
              ? "NOT SENT"
              : reservation.hotel_status === 2
              ? "WAITING"
              : reservation.hotel_status === 3
              ? "CANCELLED"
              : "CONFIRMED",
          bookingStatus:
            reservation.booking_status === 1
              ? "NEW"
              : reservation.booking_status === 2
              ? "UPDATED"
              : reservation.booking_status === 3
              ? "CANCELLED"
              : "NO SHOW",
          communicationStatus:
            reservation.communication_status === 1 ? "SENT" : "PENDING SENDING",
          operator_name: operators[reservation.operator_id]?.code,
          tourOpNo: "",
          voucher_no: reservation.voucher_no,
          lead_name: reservation.lead_name,
          product_name: resHotel?.name,
          checkInDate: reservation.start_date,
          checkOutDate: reservation.end_date,
          selling_date: reservation.selling_date,
          noOfNights:
            (Date.parse(reservation.end_date) -
              Date.parse(reservation.start_date)) /
            (1000 * 60 * 60 * 24),
          room_type: (function() {
            let room_string = "";
            reservation.rooms.forEach(room => {
              room_string +=
                roomType[
                  contract_room[reservation_rooms[room]?.contract_room_id]
                    ?.room_id
                ]?.code + " ";
            });
            return room_string;
          })(),
          board_name:
            boards[contract_has_board[reservation.board_id]?.board_id]?.code,
          adult_num: reservation.adult_num,
          child_num: reservation.child_num,
          infant_num: reservation.infant_num,
          amount: "",
          currency_name:
            currencies[contracts[reservation.contract_id]?.currency]?.code,
          contract_name: contracts[reservation.contract_id]?.contract_name,
          market_name: (function() {
            let market_string = "";
            if (reservation.contract_id !== 0) {
              contracts[reservation.contract_id].market.forEach(market => {
                market_string += markets[market].code + " ";
              });
            } else {
            }

            return market_string;
          })()
        };
        return newReservation;
      });
      Object.keys(filterState).forEach(filterKey => {
        if (filterState[filterKey] !== "") {
          if (
            filterKey !== "start_date" &&
            filterKey !== "end_date" &&
            filterKey !== "selling_date"
          ) {
            allReservations = allReservations.filter(
              res => res[filterKey] == filterState[filterKey]
            );
          } else {
            allReservations = allReservations.filter(res =>
              moment(res[filterKey]).isBetween(
                filterState[filterKey].from,
                filterState[filterKey].to,
                undefined,
                "[]"
              )
            );
          }
        }
      });
      setReservationsData(allReservations);
    }
  };
  /**************FILTER SECTION END*********************/
  const columns = [
    {
      headerName: <IntlMessages id="actions" />,
      field: "actions",
      sortable: false,
      width: 75,
      disableClickEventBubbling: true,
      renderCell: params => (
        <ActionsMenu
          params={params}
          isAuthUpdate={isAuthUpdate}
          handleClickOpen={handleClickOpen}
          setSelectedReservation={setSelectedReservation}
          setType={setType}
        />
      )
    },
    {
      headerName: <IntlMessages id="hotel.status" />,
      field: "hotel_status",
      width: 200,
      renderCell: params => {
        return handleHotelStatus(params.row.hotel_status);
      }
    },
    {
      headerName: <IntlMessages id="booking.status" />,
      field: "booking_status",
      width: 200,
      renderCell: params => {
        return handleBookingStatus(params.row.booking_status);
      }
    },
    {
      headerName: <IntlMessages id="hotel.name" />,
      field: "hotelName",
      width: 200,
      renderCell: params => {
        return hotels[params.row.hotel_id] !== undefined
          ? hotels[params.row.hotel_id].name
          : "";
      }
    },
    {
      headerName: <IntlMessages id="room.type" />,
      field: "roomType",
      width: 200
      /*  renderCell: params => {
        return roomTypes.length !== 0 ? roomTypes.find(roomType => roomType.id === params.row.hotel_id).name : '';
      }, */
    },
    {
      headerName: <IntlMessages id="check.in.date" />,
      field: "start_date",
      width: 200,
      renderCell: params => {
        return params.row.start_date !== "0000-00-00"
          ? dateToConvert(params.row.start_date)
          : "00/00/0000";
      }
    },
    {
      headerName: <IntlMessages id="check.out.date" />,
      field: "end_date",
      editable: "never",
      width: 200,
      renderCell: params => {
        return params.row.end_date !== "0000-00-00"
          ? dateToConvert(params.row.end_date)
          : "00/00/0000";
      }
    },
    {
      headerName: <IntlMessages id="communication.status" />,
      field: "communication_status",
      width: 200,
      renderCell: params => {
        return handleCommicationStatus(params.row.communication_status);
      }
    },
    {
      headerName: <IntlMessages id="reservation.create.date" />,
      field: "selling_date",
      width: 200,
      renderCell: params => {
        return params.row.selling_date !== "0000-00-00"
          ? dateToConvert(params.row.selling_date)
          : "00/00/0000";
      }
    },
    {
      headerName: <IntlMessages id="tour.operator" />,
      field: "operator_id",
      width: 200,
      renderCell: params => {
        return operators[params.row.operator_id] !== undefined
          ? operators[params.row.operator_id].name
          : "";
      }
    },
    {
      headerName: <IntlMessages id="tour.op.booking.no" />,
      field: "tourBookNo",
      width: 200
    },
    {
      headerName: <IntlMessages id="proxo.booking.no" />,
      field: "voucher_no",
      width: 200
    },
    {
      headerName: <IntlMessages id="creator.name" />,
      field: "creator_name",
      width: 200
    },
    {
      headerName: <IntlMessages id="stay.nights" />,
      field: "stayNight",
      width: 200,
      editable: "never",
      renderCell: params => {
        var nights = Math.round(
          (Date.parse(params.row.end_date) -
            Date.parse(params.row.start_date)) /
            (1000 * 60 * 60 * 24)
        );
        return nights;
      }
    },

    {
      headerName: <IntlMessages id="board.type" />,
      field: "boardType",
      width: 200,
      renderCell: params => {
        return boards[params.row.board_id]
          ? boards[params.row.board_id].code
          : "";
      }
    },
    {
      headerName: <IntlMessages id="number.of.adult" />,
      field: "adult_num",
      width: 200
    },
    {
      headerName: <IntlMessages id="number.of.child" />,
      field: "child_num",
      width: 200
    },
    {
      headerName: <IntlMessages id="number.of.infant" />,
      field: "infant_num",
      width: 200
    },
    { headerName: <IntlMessages id="amount" />, field: "amount", width: 200 },
    {
      headerName: <IntlMessages id="currency" />,
      field: "currency",
      width: 200,
      renderCell: params => {
        if (params.row.contract_id != 0) {
          var currency_id =
            contracts_selector.length !== 0
              ? contracts_selector.find(
                  contract => contract.id === params.row.contract_id
                ).currency
              : "";
          return currencies[currency_id].name;
        } else {
          return "";
        }
      }
    },
    {
      headerName: <IntlMessages id="contract" />,
      field: "contract_id",
      width: 200
    },
    {
      headerName: <IntlMessages id="market" />,
      field: "market",
      width: 200,
      renderCell: params => {
        var market_name = "";
        if (params.row.contract_id != 0) {
          var markets =
            contracts_selector.length !== 0
              ? contracts_selector.find(
                  contract => contract.id === params.row.contract_id
                ).market
              : "";
          markets.forEach(market => {
            market_name += markets_selector[market].name + ",";
          });
        }
        return market_name;
      }
    }
  ];

  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: "100%",
      height: "100%",
      margin: "fixed"
    },
    table: {
      minWidth: 500,
      margin: "fixed",
      marginTop: "1%",
      color:
        localStorage.getItem("theme-type") === "dark"
          ? "white !important"
          : "black !important",
      "& .MuiPagination-root .MuiInputBase-root-MuiTablePagination-select": {
        marginBottom: 15
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CmtCard>
        <CardContent>
          <PageContainer>
            <Container>
              <Row xs="fixed" style={{ paddingBottom: "20px" }}>
                <Grid container spacing={10}>
                  <Grid item sm={3}>
                    <IntlMessages id="check.in.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format={dateFormat}
                        placeholder={["from", "to"]}
                        onChange={handleChangeStart}
                      />
                    </Space>
                  </Grid>
                  <Grid item sm={3}>
                    <IntlMessages id="check.out.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format={dateFormat}
                        placeholder={["from", "to"]}
                        onChange={handleChangeEnd}
                      />
                    </Space>
                  </Grid>

                  <Grid item sm={3}>
                    <IntlMessages id="selling.date.range" />
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        format={dateFormat}
                        placeholder={["from", "to"]}
                        onChange={handleChangeSelling}
                      />
                    </Space>
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      id="outlined-basic"
                      name="voucher_no"
                      label={intl.formatMessage({ id: "booking.no" })}
                      variant="standard"
                      onChange={(e, value) => {
                        let event = {
                          name: "voucher_no",
                          value: e.target.value
                        };
                        filterHandleChange(event);
                      }}
                    />
                  </Grid>
                  <Grid container spacing={10}>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(operators)}
                        onChange={(e, value) => {
                          let event = {
                            name: "operator_id",
                            value: value != null ? value.id : ""
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({
                              id: "select.operator"
                            })}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: "hotel_id",
                            value: value != null ? value.id : ""
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder={intl.formatMessage({
                              id: "select.product"
                            })}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item sm={3}>
                      <Autocomplete
                        options={hotelStatus}
                        onChange={(e, value) => {
                          let event = {
                            name: "hotel_status",
                            value: value != null ? value.id : ""
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder="Select Confirm Status"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {/* <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'opBookNo',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.code}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder="Select Opeator Booking No"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Autocomplete
                        options={Object.values(hotels)}
                        onChange={(e, value) => {
                          let event = {
                            name: 'hotelConfirmeNo',
                            value: value != null ? value.id : '',
                          };
                          filterHandleChange(event);
                        }}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder="Select Hotel Confirmation No"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid> */}
                    <Grid item sm={3}>
                      <Button
                        variant="outlined"
                        className={classes.button}
                        style={{ backgroundColor: "gray" }}
                        onClick={() => filterSelected()}
                      >
                        <IntlMessages id="filter" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Row>
            </Container>
          </PageContainer>
        </CardContent>
      </CmtCard>
      <ReservationUpdate
        actionDialog={open}
        handleClose={handleClose}
        reservation={selectedReservation}
        type={type}
      />
      <Dialog
        open={releaseDialogOpen}
        onClose={handleReleaseDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Reservation Cancellation In Release"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Reservation cancellation is within the release days. If it is
            cancelled, the penalty will apply. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReleaseDialogClose}>Disagree</Button>
          <Button onClick={() => handleDelete(selectedReservation)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <ReservationUpdate dialog={open} />
      <CssBaseline position="fixed" />
      <CardHeader
        title={intl.formatMessage({ id: "show.reservation" })}
        sx={{ color: "#33658a", paddingLeft: "0px" }}
      />
      <Paper>
        <div
          position="fixed"
          // style={{
          //   height: 108 + Math.min(pageSize, pageSize.length) * 52 + "px",
          // }}
        >
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            style={{ height: 108 + pageSize * 52 + "px" }}
            position="fixed"
            rows={reservationsData}
            columns={columns}
            className={classes.table}
            groupable
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700
              },
              "& .MuiTablePagination-select": {
                marginBottom: "1rem"
              }
            }}
          />
        </div>
      </Paper>
    </div>
  );
}
