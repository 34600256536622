import {
  GET_BOARD_SUPPLAMENT,
  ADD_BOARD_SUPPLAMENT,
  UPDATE_BOARD_SUPPLAMENT,
  DELETE_BOARD_SUPPLAMENT,
  ADD_BOARD_SUPPLAMENT_REDUCTION,
  UPDATE_BOARD_SUPPLAMENT_REDUCTION,
  DELETE_BOARD_SUPPLAMENT_REDUCTION,
  SPECIAL_REDUCTION_RESET,
  SPECICAL_REDUCTION_COPY
} from "../actions/types";
import { normalizeBoardSupplaments } from "redux/schemas/boardSupplament";

const initialState = {
  boardSupplaments: [],
  reduction: [],
  special_reduction: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BOARD_SUPPLAMENT:
      const data = [];
      data["boardSupplaments"] = action.payload.board;
      data["reduction"] = action.payload.reduction;
      const normalizedData = normalizeBoardSupplaments([data]);
      return {
        ...state,
        boardSupplaments: normalizedData.entities.boardSupplaments
          ? normalizedData.entities.boardSupplaments
          : [],
        reduction: normalizedData.entities.reduction
          ? normalizedData.entities.reduction
          : []
      };

    case ADD_BOARD_SUPPLAMENT:
      let addData = [];
      addData["addBoardSupplament"] = [action.payload.board];
      addData["stateBoardSupplament"] = state.boardSupplaments;
      const normalizedAddData = normalizeBoardSupplaments([addData]);
      return {
        ...state,
        boardSupplaments: normalizedAddData.entities.boardSupplaments
          ? normalizedAddData.entities.boardSupplaments
          : state.boardSupplaments,
        reduction: state.reduction
      };

    case UPDATE_BOARD_SUPPLAMENT:
      const updateData = [];
      updateData["updateBoardSupplament"] = [action.payload.board];
      updateData["stateBoardSupplament"] = state.boardSupplaments;
      const normalizedUpdateData = normalizeBoardSupplaments([updateData]);

      return {
        ...state,
        boardSupplaments: normalizedUpdateData.entities.boardSupplaments
          ? normalizedUpdateData.entities.boardSupplaments
          : state.boardSupplaments,
        reduction: state.reduction
      };

    case DELETE_BOARD_SUPPLAMENT:
      let deleteBoardSupplament = state.boardSupplaments;
      delete deleteBoardSupplament[action.payload.board];
      let deleteReduction = state.reduction;
      delete deleteReduction[action.payload.reduction];

      return {
        ...state,
        reduction: deleteReduction,
        boardSupplaments: deleteBoardSupplament
      };

    case ADD_BOARD_SUPPLAMENT_REDUCTION:
      let indexAdd = action.payload.reduction;
      let addReductionData = [];
      addReductionData["addReduction"] = [indexAdd];
      addReductionData["stateReduction"] = state.reduction;
      const normalizedAddReduction = normalizeBoardSupplaments([
        addReductionData
      ]);
      let state_special_reduction = state.special_reduction;
      if (!action.payload.id && !action.payload.reduction) {
        let casperID = "";

        if (Object.keys(state.special_reduction).length) {
          const lastSpecialReduction = Object.values(
            state.special_reduction
          ).slice(-1)[0];
          if (lastSpecialReduction.hasOwnProperty("id")) {
            casperID = lastSpecialReduction.id + 1;
          } else {
            casperID = lastSpecialReduction.casperID + 1;
          }
        } else {
          if (Object.values(state.reduction).length) {
            casperID = Object.values(state.reduction).slice(-1)[0].id + 1;
          } else {
            casperID = 1;
          }
        }

        action.payload["casperID"] = casperID;
        state_special_reduction[state_special_reduction.length] =
          action.payload;
      }
      return {
        ...state,
        reduction: normalizedAddReduction.entities.reduction
          ? normalizedAddReduction.entities.reduction
          : state.reduction,
        special_reduction: state_special_reduction
          ? state_special_reduction
          : []
      };

    case UPDATE_BOARD_SUPPLAMENT_REDUCTION:
      let updateReductionData = [];
      let shallowSpecialReductionEdit = [...state.special_reduction];
      let indexUpdate = action.payload.reduction;
      updateReductionData["updateReduction"] = [indexUpdate];
      updateReductionData["stateReduction"] = state.reduction;
      const normalizedUpdateReduction = normalizeBoardSupplaments([
        updateReductionData
      ]);

      if (!action.payload.reduction) {
        if (!action.payload.id) {
          const isThereReduction = shallowSpecialReductionEdit.findIndex(
            i => i.casperID == action.payload.casperID
          );
          if (isThereReduction != -1) {
            shallowSpecialReductionEdit[isThereReduction] = action.payload;
          } else {
            shallowSpecialReductionEdit[action.payload.casperID] =
              action.payload;
          }
        } else {
          const updateIndex = shallowSpecialReductionEdit.findIndex(
            i => i.id == action.payload.id
          );
          shallowSpecialReductionEdit[updateIndex] = action.payload;
        }
      }

      return {
        ...state,
        reduction: normalizedUpdateReduction.entities.reduction
          ? normalizedUpdateReduction.entities.reduction
          : state.reduction,
        special_reduction: shallowSpecialReductionEdit
      };

    case DELETE_BOARD_SUPPLAMENT_REDUCTION:
      let shallowSpecialReduction = [...state.special_reduction];
      const deleteJustReduction = state.reduction;
      /* eğer silinen veri v.t.'dan silinmişse içerisinde reduction değeri vardır. */
      if (action.payload.reduction) {
        /* bu değere göre hem state içerisindeki reductiondan ve kullanıcıya anlık gösterilen shallowSpecialReduction silinir ya da filtrelenir */
        let indexDelete = Number(action.payload.reduction);
        delete deleteJustReduction[indexDelete];
        shallowSpecialReduction = Object.values(shallowSpecialReduction).filter(
          i => i.id != indexDelete
        );
      } else {
        /* eğer silinen sadece state içinden ise bu blok çalışır. payload içerisinde id varsa tempId'ye id, eğer casperID varsa casperID atanır.
        payload içerisinde gönderilen isThereID değişkeni true ise shallowSpecialReduction içinden id'ye göre filtrelenir. Eğer false değeri ise
        shallowSpecialReduction içinden casperID'ye göre filtrelenir. */
        let tempId = action.payload.id
          ? action.payload.id
          : action.payload.casperID;
        if (action.payload.isThereID) {
          shallowSpecialReduction = Object.values(
            shallowSpecialReduction
          ).filter(i => i.id != tempId);
        } else {
          shallowSpecialReduction = Object.values(
            shallowSpecialReduction
          ).filter(i => i.casperID != tempId);
        }
      }
      return {
        ...state,
        reduction: deleteJustReduction,
        special_reduction: shallowSpecialReduction
      };
    case SPECIAL_REDUCTION_RESET:
      return { ...state, special_reduction: [] };
    case SPECICAL_REDUCTION_COPY:
      return { ...state, special_reduction: action.payload };
    default:
      return state;
  }
};
