import axios from "axios";

export default axios.create({
  baseURL: `https://stageb2bapi.mundosai.com/api/`, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});


// import axios from "axios";

// export default axios.create({
//   baseURL: `http://localhost:80/api/`, //YOUR_API_URL HERE
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// import axios from "axios";

// export default axios.create({
//   baseURL: `https://b2bapi.mundosai.com/api/`, //YOUR_API_URL HERE
//   headers: {
//     "Content-Type": "application/json",
//   },
// });