// import axios from "axios";

// export let loggerEndpoint = "http://localhost:3030/proxolab/api/v1/";

// export default axios.create({
//   baseURL: "http://localhost:3030/",
//   headers: {
//     "Content-Type": "application/json"
//     // Authorization: Bearer ${token},
//   }
// });

// //// live and stage logger


import axios from "axios";

export let loggerEndpoint = "https://b2b.proxolab.com:82/proxolab/api/v1/";

export default axios.create({
    baseURL: "https://b2b.proxolab.com:82/",
    headers: {
        "Content-Type": "application/json",
        // Authorization: Bearer ${token},
    },
});
