import React, { useEffect } from "react";
import MaterialTable, { MTableEditRow } from "material-table";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  getSeasonService,
  addSeasonService,
  updateSeasonsService,
  deleteSeasonsService
} from "services/season";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { alertTitleClasses } from "@mui/material";
import { useIntl } from "react-intl";
import ModeCheckIcon from "@mui/icons-material/Check";
import ModeCloseIcon from "@mui/icons-material/Close";
import Loader from "../Common/Loader";

const theme = createTheme({
  palette: {
    backgroundColor: orange[400]
  }
});

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(orange[400]),
    backgroundColor: orange[400],
    "&:hover": {
      backgroundColor: orange[500]
    }
  }
}))(Button);

const MySwal = withReactContent(Swal);

export default function NewBoard({ isAuthAdd, isAuthUpdate, isAuthDelete }) {
  //const date = new Date();
  //const today = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (Object.values(seasons).length === 0) {
      dispatch(getSeasonService());
    }
  }, [dispatch]);
  const { seasons } = useSelector(({ seasons }) => seasons);
  const contracts = useSelector(state => state.contract.contracts);

  //For keyboard date picker
  const date = new Date();
  const today = `${date.getFullYear()}-${date.getMonth() +
    1}-${date.getDate()}`;

  //For Invalid Date Control
  const controlInvalidDate = date => {
    let invalidFlag = true;
    if (date == "Invalid date" || date == undefined) {
      invalidFlag = false;
    }
    return invalidFlag;
  };

  //Same season types can't have same date range
  const seasonControl = newData => {
    const seasonDateFlag = Object.values(seasons).reduce((res, item) => {
      if (!res) return res;

      //if element intersection with itself do nothing
      if (item.id === newData.id) return res;
      if (item.type == newData.type) {
        if (
          Date.parse(newData.start_date) <= Date.parse(item.end_date) &&
          Date.parse(newData.start_date) >= Date.parse(item.start_date)
        ) {
          res = false;
        } else if (
          Date.parse(newData.end_date) >= Date.parse(item.start_date) &&
          Date.parse(newData.end_date) <= Date.parse(item.end_date)
        ) {
          res = false;
        } else if (
          Date.parse(newData.start_date) <= Date.parse(item.end_date) &&
          Date.parse(newData.end_date) >= Date.parse(item.start_date)
        ) {
          res = false;
        } else {
          res = true;
        }
      }
      return res;
    }, true);
    return seasonDateFlag;
  };

  //To check if the season is in the contract
  const hasSeason = season_id => {
    var has = true;
    Object.values(contracts).forEach(contract => {
      if (contract.season_id === season_id) {
        has = false;
      }
    });
    return has;
  };

  const seasonCode = (type, start_date, end_date) => {
    let code = "";
    if (type == "1") {
      code = "W";
    } else if (type == "2") {
      code = "S";
    } else if (type == "3") {
      code = "Y";
    }
    //If start and end date are equal add just one year to code
    if (start_date != undefined && end_date != undefined) {
      if (start_date.substring(0, 4) == end_date.substring(0, 4)) {
        code += start_date.substring(2, 4);
      } else if (
        (start_date.substring(2, 4) != end_date.substring(2, 4)) &
        (type !== "3")
      ) {
        code += start_date.substring(2, 4) + "-" + end_date.substring(2, 4);
      } else if (
        (start_date.substring(2, 4) != end_date.substring(2, 4)) &
        (type == "3")
      ) {
        code += start_date.substring(2, 4);
      }
    }
    return code;
  };

  const tableColumns = [
    {
      title: (
        <h5>
          <b>{intl.formatMessage({ id: "type" })}</b>
        </h5>
      ),
      field: "type",
      lookup: {
        1: "Winter",
        2: "Summer",
        3: "Year"
      },
      validate: rowData => !!rowData.type
    },

    {
      title: intl.formatMessage({ id: "name" }),
      field: "name",
      validate: rowData => !!rowData.name,
      style: {
        fontSize: "4rem",
        color: "red"
      },
      headerStyle: {
        fontSize: "20px",
        fontWeight: "bold"
      }
    },

    {
      title: (
        <h5>
          <b>
            <IntlMessages id="start.date" />
          </b>
        </h5>
      ),
      field: "start_date",
      type: "date",
      render: rowData => {
        return moment(rowData.start_date).format("DD/MM/YYYY");
      },
      validate: rowData =>
        !controlInvalidDate(rowData.start_date) ? false : true,
      editComponent: props => (
        (props.rowData.start_date = props.value
          ? moment(props.value).format("YYYY-MM-DD")
          : today),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="start_date"
              format="dd/MM/yyyy"
              autoOk="true"
              minDate={today}
              value={
                props.value
                  ? moment(props.value).format("YYYY-MM-DD")
                  : moment(props.rowData.start_date).format("YYYY-MM-DD")
              }
              onChange={date =>
                props.onChange(moment(date).format("YYYY-MM-DD"))
              }
              animateYearScrolling
              InputLabelProps={{
                shrink: true
              }}
            />
          </MuiPickersUtilsProvider>
        )
      )
    },

    {
      title: (
        <h5>
          <b>
            <IntlMessages id="end.date" />
          </b>
        </h5>
      ),
      field: "end_date",
      type: "date",
      render: rowData => {
        return moment(rowData.end_date).format("DD/MM/YYYY");
      },
      validate: rowData =>
        !controlInvalidDate(rowData.end_date) ? false : true,
      editComponent: props => (
        (props.rowData.end_date = props.value
          ? moment(props.value).format("YYYY-MM-DD")
          : today),
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              name="end_date"
              format="dd/MM/yyyy"
              autoOk="true"
              minDate={
                props.rowData.start_date ? props.rowData.start_date : today
              }
              value={
                props.value
                  ? moment(props.value).format("YYYY-MM-DD")
                  : moment(props.rowData.end_date).format("YYYY-MM-DD")
              }
              onChange={date =>
                props.onChange(moment(date).format("YYYY-MM-DD"))
              }
              animateYearScrolling
              InputLabelProps={{
                shrink: true
              }}
            />
          </MuiPickersUtilsProvider>
        )
      )
    },
    {
      title: (
        <h5>
          <b>
            <IntlMessages id="code" />
          </b>
        </h5>
      ),
      field: "code",
      editable: "never"
    }
  ];
  return (
    <MaterialTable
      columns={tableColumns}
      data={Object.values(seasons)}
      title=""
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 20, 30, 40],
        actionsColumnIndex: -1,
        search: false,
        addRowPosition: "first"
      }}
      actions={[
        isAuthAdd
          ? undefined
          : {
              icon: "add",
              disabled: true,
              position: "toolbar",
              tooltip: "You are not authorized"
            }
      ]}
      icons={{
        Add: props => (
          <ThemeProvider theme={theme}>
            <ColorButton
              variant="contained"
              color="backgroundColor"
              startIcon={<AddBoxIcon />}
            >
              <IntlMessages id="add" />
            </ColorButton>
          </ThemeProvider>
        ),
        Check: () => <ModeCheckIcon style={{ color: "green" }} />,
        Clear: () => <ModeCloseIcon sx={{ color: "red" }} />
      }}
      components={{
        OverlayLoading: () => <Loader />,
        EditRow: props => {
          return (
            <MTableEditRow
              {...props}
              onKeyDown={e => {
                if (
                  e.keyCode === 27 ||
                  e.keyCode === 109 ||
                  e.keyCode === 189
                ) {
                  e.preventDefault();
                }
              }}
              onEditingCanceled={(mode, rowData) => {
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (mode == "update") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "add") {
                      Swal.fire({
                        title: intl.formatMessage({ id: "are.you.sure?" }),
                        text: intl.formatMessage({
                          id: "do.you.want.to.cancel.the.changes"
                        }),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#41C329",
                        allowOutsideClick: false,
                        cancelButtonColor: "#d33",
                        confirmButtonText: intl.formatMessage({ id: "yes" }),
                        cancelButtonText: intl.formatMessage({ id: "no" })
                      }).then(result => {
                        if (result.isConfirmed) {
                          props.onEditingCanceled(mode, rowData);
                          resolve();
                        } else if (result.isDenied) {
                          reject();
                        }
                      });
                    }
                    if (mode == "delete") {
                      props.onEditingCanceled(mode, rowData);
                    }
                  });
                });
              }}
            />
          );
        },
        onRowAdd: props => (
          <MTableEditRow
            {...props}
            onKeyDown={e => {
              if (e.keyCode === 27 || e.keyCode === 109 || e.keyCode === 189) {
                e.preventDefault();
              }
            }}
          />
        )
      }}
      editable={{
        deleteTooltip: row =>
          isAuthDelete ? "Delete" : "You are not authorized",
        editTooltip: row =>
          isAuthUpdate ? "Update" : "You are not authorized",
        isDeletable: row => (isAuthDelete ? true : false),
        isEditable: row => (isAuthUpdate ? true : false),
        onRowAdd: isAuthAdd
          ? newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const otherSeasons = Object.values(seasons).filter(
                    season => newData.code !== season.code
                  );
                  const season_code = seasonCode(
                    newData.type,
                    newData.start_date,
                    newData.end_date
                  );
                  newData = { ...newData, code: season_code };
                  if (
                    Object.values(otherSeasons).filter(
                      season => season.code === newData.code.toUpperCase()
                    ) &&
                    Object.values(otherSeasons).filter(
                      season => season.name === newData.name.toUpperCase()
                    ).length === 0
                  ) {
                    newData.code = newData.code.toUpperCase();
                    newData.name = newData.name.toUpperCase();
                    if (
                      !moment(newData.start_date).isSameOrBefore(
                        newData.end_date
                      )
                    ) {
                      MySwal.fire(
                        "Oops...",
                        intl.formatMessage({
                          id: "end.date.cant.be.smaller.than.start.date"
                        }),
                        "error"
                      );
                      reject();
                    } else if (!seasonControl(newData)) {
                      MySwal.fire(
                        "Oops...",
                        intl.formatMessage({
                          id: "this.season.has.same.date.range"
                        }),
                        "error"
                      );
                      reject();
                    } else {
                      dispatch(addSeasonService(newData)).then(res => {
                        if (res === 201) {
                          MySwal.fire({
                            icon: "success",
                            text:
                              intl.formatMessage({ id: "season" }) +
                              intl.formatMessage({
                                id: "created.successfully"
                              })
                          });
                        }
                      });
                      resolve();
                    }
                  } else {
                    MySwal.fire(
                      "Oops...",
                      intl.formatMessage({ id: "same.name.or.code" }),
                      "error"
                    );
                    reject();
                  }
                }, 1000);
              })
          : undefined,
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const otherSeasons = Object.values(seasons).filter(
                season => oldData.code !== season.code
              );
              const season_code = seasonCode(
                newData.type,
                newData.start_date,
                newData.end_date
              );
              newData = { ...newData, code: season_code };

              if (
                Object.values(otherSeasons).filter(
                  season => season.code === newData.code.toUpperCase()
                ) &&
                Object.values(otherSeasons).filter(
                  season => season.name === newData.name.toUpperCase()
                ).length === 0
              ) {
                newData.code = newData.code.toUpperCase();
                newData.name = newData.name.toUpperCase();

                if (
                  !moment(newData.start_date).isSameOrBefore(newData.end_date)
                ) {
                  MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: intl.formatMessage({
                      id: "end.date.cant.be.smaller.than.start.date"
                    })
                  });
                  reject();
                } else if (!seasonControl(newData)) {
                  MySwal.fire(
                    "Oops...",
                    intl.formatMessage({
                      id: "this.season.has.same.date.range"
                    }),
                    "error"
                  );
                  reject();
                } else {
                  dispatch(updateSeasonsService(newData)).then(res => {
                    if (res === 201) {
                      MySwal.fire({
                        icon: "success",
                        text:
                          intl.formatMessage({ id: "season" }) +
                          intl.formatMessage({ id: "updated.successfully" })
                      });
                    }
                  });
                  resolve();
                }
              } else {
                MySwal.fire(
                  "Oops...",
                  intl.formatMessage({ id: "same.name.or.code" }),
                  "error"
                );
                reject();
              }
            }, 1000);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              if (hasSeason(oldData.id)) {
                dispatch(deleteSeasonsService(oldData.id)).then(res => {
                  if (res === 201) {
                    MySwal.fire({
                      icon: "success",
                      text:
                        intl.formatMessage({ id: "season" }) +
                        intl.formatMessage({ id: "deleted.successfully" })
                    });
                  }
                });
              } else {
                MySwal.fire({
                  icon: "error",
                  title: "Oops...",
                  text:
                    intl.formatMessage({ id: "season" }) +
                    intl.formatMessage({ id: "is.in.use" })
                });
              }
              resolve();
            }, 1000);
          })
      }}
    />
  );
}
