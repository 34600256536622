import React, { useEffect, useState } from "react";
import { makeStyles, lighten } from "@material-ui/core/styles";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  addContractService,
  controlContractConfilict,
  controlContractRoomRateType,
  updateContractService
} from "services/contract";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import SelectAll from './SelectAll';
import { isSavedConract } from "redux/actions/contract";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { getAdminCompaniesService } from "services/company";
import { blue, green, purple } from "@material-ui/core/colors";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Button,
  FormControl,
  TextField,
  MenuItem,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  InputLabel,
  InputBase
} from "@material-ui/core";
import { AgeNumberFormatCustom } from "../Common/NumberFormat/NumberFormat";
import moment from "moment";
import {
  nonEnterprise,
  companyData,
  officeMemo
} from "redux/selector/companyHasOfficeSelector";
import _ from "lodash";
import { useIntl } from "react-intl";
// import { contractHasBoard } from 'redux/selector/contract';
import { CHECK_CONTRACT_DATA_IS_UPDATE } from "redux/actions/types";
import IntlMessages from "@jumbo/utils/IntlMessages";
import MultiSelect from "../Common/MultiSelect";
import { multiDataSetter } from "@jumbo/utils/commonHelper";
import "../../../styles/css/contractSeason.css";
import { fontSize } from "@mui/system";
import currency from "redux/reducers/currency";
import { useParams } from "react-router-dom";
import contract from "redux/reducers/contract";
import { getHotel } from "redux/actions/hotels";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ContractSeason(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(isSavedConract(""));
    dispatch(getAdminCompaniesService());
  }, [dispatch]);
  let param = useParams();
  //dropdownlarda görüntülenmesi g0ereken veriler
  const filter = createFilterOptions();
  const { rateTypes } = useSelector(({ rateTypes }) => rateTypes);
  const { boards } = useSelector(({ boards }) => boards);
  const { seasons } = useSelector(({ seasons }) => seasons);
  const { operators } = useSelector(({ operators }) => operators);
  const { hotels } = useSelector(({ hotels }) => hotels);
  const { currencies } = useSelector(({ currencies }) => currencies);
  const { markets } = useSelector(({ markets }) => markets);
  const { marketCountries } = useSelector(({ markets }) => markets);
  const { authUser } = useSelector(({ auth }) => auth);
  const { allotmentType } = useSelector(({ allotmentType }) => allotmentType);
  const { selectedContract } = useSelector(({ contract }) => contract);
  const { board } = useSelector(({ contract }) => contract);
  const { contract_has_board } = useSelector(({ contract }) => contract);
  const { market } = useSelector(({ contract }) => contract);
  const { contracts } = useSelector(({ contract }) => contract);
  const { countries } = useSelector(({ contract }) => contract);
  const { entities } = useSelector(({ companyHasOffice }) => companyHasOffice);
  const onlyCompany = useSelector(nonEnterprise);
  const objectCompany = useSelector(companyData);
  const objectOffice = useSelector(officeMemo);
  const theme = createTheme({
    palette: {
      primary: green,
      secondary: blue
    }
  });
  //tarihler için default değerler
  const [selectedStartDate, handleStartDateChange] = useState(new Date());
  const [selectedEndDate, handleEndDateChange] = useState(selectedStartDate);
  const [contractInfo, setContractInfo] = useState(
    selectedContract != 0 && !param.hasOwnProperty("contractId")
      ? {
          ...contracts[selectedContract],
          min_child_age: "02.00",
          max_infant_age: "01.99"
        }
      : { min_child_age: "02.00", max_infant_age: "01.99" }
  );

  const [hotelInformation, setHotelInformation] = useState({});
  const [tempMarkets, setTempMarkets] = useState([]);
  const [allCountries, setAllCountries] = useState(
    selectedContract != 0 && false ? Object.values(countries) : []
  );

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [operatorState, setOperatorState] = useState([]);
  const [boardState, setBoardState] = useState([]);
  const [rateTypeState, setRateTypeState] = useState([]);
  const [allotmentTypeState, setAllotmentTypeState] = useState([]);
  const [currenciesState, setCurrenciesState] = useState([]);
  const { permissionsByAuthUser } = useSelector(({ permission }) => permission);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [hotelCache, setHotelCache] = useState([]);
  const [hotelData, setHotelData] = useState([]);
  const officeID = useSelector(
    state => state?.companyHasOffice.entities.users[authUser.id]?.pivot_office
  );
  // const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [selectedCompany, setSelectedCompany] = useState();
  const [updateButtonDisable, setUpdateButtonDisable] = useState(true);
  const contractStatusControl = contracts[selectedContract]
    ? contracts[selectedContract].contract_statuses_id == 1 ||
      contracts[selectedContract].contract_statuses_id == 5
      ? true
      : false
    : true;
  let countryInMarket = {};
  Object.entries(markets).forEach((key, value) => {
    if (key[1].countries !== null) {
      countryInMarket[value] = key[1];
    }
  });
  const intl = useIntl();
  const MySwal = withReactContent(Swal);
  const sweetAlerts = (variant, text) => {
    MySwal.fire({
      icon: variant,
      title: "",
      text: text
    });
  };
  let confilictCountries = "";
  let confilictOpertors = "";
  let isAuthAdd = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.post === 1
  );
  let isAuthUpdate = permissionsByAuthUser.some(
    permission => permission.route_id == 2 && permission.put === 1
  );
  useEffect(() => {
    if (onlyCompany.length != 0) {
      if (authUser.authority_level == 10) {
        const filteredCompanies = onlyCompany.filter(
          company =>
            company.offices.length > 0 &&
            company.offices.some(
              office =>
                objectOffice[office].get_destination !== null &&
                objectOffice[office].get_destination?.destinations !== "[]"
            )
        );
        setCompanyInfo(filteredCompanies);
        //Sadece 1 tane company varsa seçim yapmasın otomatik olarak o company seçili gelsin
        if (onlyCompany.length == 1) {
          setSelectedCompany(onlyCompany[0]);
        }
      } else if (
        authUser.authority_level == 20 ||
        authUser.authority_level == 30 ||
        authUser.authority_level == 11
      ) {
        setSelectedCompany(
          objectCompany[parseInt(localStorage.selectedCompany)]
        );
      }
      /* else if (authUser.authority_level == 30) {
        setSelectedCompany(objectCompany[parseInt(localStorage.selectedCompany)]);
      } */
    }
  }, [onlyCompany]);

  useEffect(() => {
    dispatch({
      type: CHECK_CONTRACT_DATA_IS_UPDATE,
      payload: updateButtonDisable
    });
  }, [updateButtonDisable]);

  useEffect(() => {
    /* 
    OTELLERİN USER SEVİYELERİNE GÖRE GELMESİ GEREKİYOR
    1-) ENTERPRİSE BİR USER (level = 10) COMPANY SEÇİMİ YAPACAK. SEÇİLİ OLAN COMPANYNİN DESTİNATİONLARINDA OLAN OTELLERİ LİSTELEYEBİLECEK.
        OFFİCELERE DESTİNATİON ATANDIĞI İÇİN COMPANYNİN DESTİNATİONLARI ONUN BÜTÜN OFFİCELERİNİN DESTİNATİONLARI OLACAK. BUNUN İÇİN SEÇİLİ 
        OLAN COMPANYNİN BÜTÜN OFFİCELERİ FOR DÖNÜLÜP COMPANYNİN DESTİNATİONU ELDE EDİLECEK. DAHA SONRA HOTELLERDE BU DESTİNATİON BİLGİLERİNE 
        DENK GELEN HOTELLER FİLTRELENECEK. 
        (FİLTRELEME KIRILIMI SEEDERLAR EKSİK OLDUĞU İÇİN ŞİMDİLİK ÜLKEYE KADAR KONTROL EDİLİYOR DÜZELİNCE CİTYE KADAR EKLENECEK)
    2-) ENTERPRİSE BİR COMPANYNİN COMPANY SEVİYESİNDEKİ BİR USERI (level 20) EĞER TEK BİR COMPANYDE ÇALIŞMIYORSA GİRİŞ YAPTIKTAN SONRA SEÇMİŞ
        OLDUĞU COMPANY ÜZERİNDEN İŞLEM YAPABİLİR. CONTRACT EKLERKEN COMPANY DEĞİŞTİRMEK İSTERSEN SİDEBARDAN DEĞİŞTİRECEK.
        SEÇİLİ GELEN COMPANYNİN ENTERPRİSEDAKİ GİBİ BÜTÜN OFFİCELERİ DÖNÜLÜP ONUN DESTİNATİONA GÖRE HOTELLER FİLTRELENECEK. MADDE 1 DEKİ GİBİ
    3-) OFFİCE LEVEL USERLAR (LEVEL 30) GİRİŞ YAPTIKTAN SONRA SEÇMİŞ OLDUKLARI COMPANY, COMPANY BÖLÜMÜNE DÜŞECEK.MADDE 2 DEKİ GİBİ COMPANY DEĞİŞTİRMEK
        İSTERSE SİDEBARDAN DEĞİŞTİRECEK. OFFİCE USERI GİRİŞ YAPTIKTAN SONRA OFFİCEDE SEÇTİĞİ İÇİN O SEÇİLİ OLAN OFFİCENİN DESTİNATİONLARINA GÖRE
        HOTELLER FİLTRELENECEK
    4-) COMPANY BİR COMPANYNİN COMPANY LEVEL USERI (LEVEL 11). TEK BİR COMPANYSİ OLDUĞU İÇİN O COMPANY OTOMATİK OLARAK DÜŞECEK. FARKLI BİR COMPANY
        OLMADIĞI İÇİN OTELLERDE HERHANGİ BİR FİLTRELEME OLMADAN DİREK SİSTEME DÜŞECEK 
    */
    setContractInfo({
      ...contractInfo
    });

    if (selectedCompany) {
      let companyDestination = {};
      let mergeCompany = {};
      let filteredHotel = [];
      if (
        authUser.authority_level == 10 ||
        authUser.authority_level == 20 ||
        authUser.authority_level == 11
      ) {
        if (!hotelCache[selectedCompany.id]) {
          selectedCompany.offices.map(element => {
            if (objectOffice[element].get_destination?.destinations) {
              if (companyDestination[0]) {
                companyDestination[0] = JSON.parse(
                  objectOffice[element].get_destination.destinations
                ).reduce((acc, curr) => {
                  if (!acc[curr.id]) {
                    acc[curr.id] = curr;
                  }
                  return acc;
                }, companyDestination[0]);
              } else {
                companyDestination[0] = JSON.parse(
                  objectOffice[element].get_destination.destinations
                ).reduce((acc, curr) => {
                  acc[curr.id] = curr;
                  return acc;
                }, {});
              }
            }
          });

          filteredHotel = Object.values(hotels).filter(element => {
            return Object.values(companyDestination[0]).find(compDest => {
              return (
                compDest["region_id"] == [element.region_id] &&
                compDest["country_id"] == [element.country_id]
              );
            });
          });
          setHotelCache({ ...hotelCache, [selectedCompany.id]: filteredHotel });
          setHotelData(filteredHotel);
        } else {
          setHotelData(hotelCache[selectedCompany.id]);
        }

        // if (filteredHotel.length == 0) {
        //   setContractInfo({ ...contractInfo, hotel_id: null });
        //   setHotelInformation({});
        // }
      } else if (authUser.authority_level == 30) {
        companyDestination[0] = JSON.parse(
          objectOffice[parseInt(localStorage.getItem("selectedOffice"))][
            "get_destination"
          ]["destinations"]
        );

        filteredHotel = Object.values(hotels).filter(element => {
          return companyDestination[0].find(compDest => {
            return (
              compDest["region_id"] == [element.region_id] &&
              compDest["country_id"] == [element.country_id]
            );
          });
        });
        setHotelData(filteredHotel);

        // if (filteredHotel.length == 0) {
        //   setContractInfo({ ...contractInfo, hotel_id: null });
        //   setHotelInformation({});
        // }
      }
      /* else if (authUser.authority_level == 20) {
        if (!hotelCache[selectedCompany.id]) {
        selectedCompany.offices.map(element => {
          if (objectOffice[element].get_destination?.destinations) {
            if (companyDestination[0]) {
              mergeCompany = _.merge(companyDestination[0], JSON.parse(objectOffice[element].get_destination.destinations));
              companyDestination[0] = mergeCompany;
            } else {
              companyDestination[0] = JSON.parse(objectOffice[element].get_destination.destinations);
            }
          }
        });
        filteredHotel = Object.values(hotels).filter(
          element =>
            companyDestination[0]['regionID'][element.region_id] &&
            companyDestination[0]['regionID'][element.region_id]['countryID'][element.country_id],
        );
        setHotelCache({ ...hotelCache, [selectedCompany.id]: filteredHotel });
        setHotelData(filteredHotel);
        } else {
          setHotelData(hotelCache[selectedCompany.id]);
        }

        if (filteredHotel.length == 0) {
          setContractInfo({ ...contractInfo, hotel_id: null });
          setHotelInformation({});
        }
      } */
    } else {
      if (hotelData) {
        setHotelData([]);
        setHotelInformation({});
      }
    }
  }, [selectedCompany]);
  const setContractCountriesState = market => {
    // let tempMarkets = [];
    // let ids = [];
    // let tempCountries = [];
    // tempMarkets.push(market);
    // tempMarkets.forEach(tempMarket => {
    //   tempMarket.map(element => {
    //     ids.push(element.id);
    //     if (selectedContract != 0 && element.countries) {
    //       element.countries.map(country => {
    //         tempCountries = tempCountries.concat(marketCountries[country]);
    //       });
    //     } else {
    //       if (element.countries) {
    //         element.countries.map(country => {
    //           tempCountries = tempCountries.concat(marketCountries[country]);
    //         });
    //       }
    //     }
    //   });
    //   tempCountries = tempCountries.filter((value, index) => {
    //     const _value = JSON.stringify(value);
    //     return (
    //       index ===
    //       tempCountries.findIndex(obj => {
    //         return JSON.stringify(obj) === _value;
    //       })
    //     );
    //   });
    // });
    // setAllCountries(tempCountries);
    // return ids;
    const ids = market?.map(k => k.id);
    const baseCountries = Object.values(marketCountries);

    let selectCountries = [];
    for (const v of market) {
      for (const l of v.countries) {
        if (!selectCountries.find(t => t === l)) selectCountries.push(l);
      }
    }
    setAllCountries(
      baseCountries
        .map(k => (selectCountries.includes(k.id) ? k : false))
        .filter(k => k !== false)
    );
    return ids;
  };

  useEffect(() => {
    if (selectedContract != 0 && !param.hasOwnProperty("contractId")) {
      let tempOperators = [];
      let tempBoards = [];
      let tempRateTypes = [];
      let tempMarkets = [];
      let board = [];
      if (
        contractInfo.max_child_age != null ||
        contractInfo.max_infant_age != null
      ) {
        contracts[selectedContract].contract_has_board.map(b => {
          board.push(contract_has_board[b]?.board_id);
        });
        setContractInfo({
          ...contractInfo,
          // checkChild: false,
          // disabledChild: false,
          // disabledInf: false,
          // checkInf: false,
          // include_countries: contractInfo.contractCountries,
          include_countries: contractInfo.contractCountries?.filter(
            (v, i, a) => a.findIndex(t => t.id == v.id) == i
          ),
          boards: Object.values(board)
        });
      } else {
        setContractInfo({
          ...contractInfo,
          // checkChild: true,
          // disabledChild: true,
          // disabledInf: true,
          // checkInf: true,
          // include_countries: contractInfo.contractCountries,
          include_countries: contractInfo.contractCountries?.filter(
            (v, i, a) => a.findIndex(t => t.id == v.id) == i
          ),
          boards: Object.values(board)
        });
      }

      setSelectedCompany(objectCompany[contractInfo.company_id]);
      //setHotelInformationState(hotels[contractInfo.hotel_id]);
      setHotelInformation(hotels[contractInfo.hotel_id]);
      //  setContractInfo({ ...contractInfo, include_countries: Object.values(countries) });
      contractInfo.operators.map(op => {
        tempOperators.push(operators[op]);
      });
      contractInfo.contract_has_board.map(b => {
        tempBoards.push(boards[contract_has_board[b].board_id]);
      });
      contractInfo.rate_types.map(r => {
        tempRateTypes.push(rateTypes[r]);
      });
      contractInfo.market.map(m => {
        if (tempMarkets.indexOf(m) == -1) {
          tempMarkets.push(market[m]);
        }
      });
      setOperatorState(tempOperators);
      //tempBoards.shift()
      setBoardState(tempBoards);
      setRateTypeState(tempRateTypes);
      setAllotmentTypeState(allotmentType[contractInfo.allotment_type_id]);
      setCurrenciesState(currencies[contractInfo.currency]);
      setTempMarkets(tempMarkets);
    }
  }, [hotels]);

  const handleOnChange = (name, value) => {
    setUpdateButtonDisable(false);
    let tempOperators = [];
    let tempOperatorsCode = [];
    let tempBoards = [];
    let tempRateTypes = [];
    if (name == "hotel_id") {
      //setHotelInformationState(value);
      setHotelInformation(value);
      setContractInfo({ ...contractInfo, [name]: value.id });
    } else if (name == "market") {
      let ids = setContractCountriesState(value);
      let temp_include_countries = [];
      value.map(market => {
        if (contractInfo.include_countries) {
          contractInfo.include_countries.map(include_country => {
            if (market.countries.includes(include_country.id)) {
              temp_include_countries.push(include_country);
            }
          });
        }
      });
      let inc_countries = [];
      inc_countries = temp_include_countries.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          temp_include_countries.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      setTempMarkets(value);

      setContractInfo({
        ...contractInfo,
        [name]: ids,
        include_countries: inc_countries,
        operators: []
      });
      setOperatorState([]);
    } else if (name == "operators") {
      value.map(val => {
        tempOperators.push(val.id);
        tempOperatorsCode.push({ id: val.id, code: val.code });
      });
      setOperatorState(value);
      setContractInfo({
        ...contractInfo,
        [name]: tempOperators,
        tempOperatorCodes: tempOperatorsCode
      });
    } else if (name == "boards") {
      value.map(val => {
        tempBoards.push(val.id);
      });
      setBoardState(value);
      setContractInfo({ ...contractInfo, [name]: tempBoards });
    } else if (name == "rate_types") {
      value.map(val => {
        tempRateTypes.push(val.id);
      });
      setRateTypeState(value);
      setContractInfo({ ...contractInfo, [name]: tempRateTypes });
    } else if (name == "allotment_type_id") {
      setAllotmentTypeState(value);
      setContractInfo({ ...contractInfo, [name]: value.id });
    } else if (name == "currency") {
      setCurrenciesState(value);
      setContractInfo({ ...contractInfo, [name]: value.id });
    } else if (name == "season_id") {
      setContractInfo({
        ...contractInfo,
        [name]: value,
        start_date: seasons[value]?.start_date,
        end_date: seasons[value]?.end_date
      });
    } else if (name == "company_id") {
      //setCompanyInformationState(value);
      setSelectedCompany(objectCompany[value]);

      setContractInfo({ ...contractInfo, [name]: value, hotel_id: null });
      setHotelInformation({});
    } else if (name == "country") {
      setUpdateButtonDisable(false);
      setContractInfo({
        ...contractInfo,
        include_countries: value,
        operators: []
      });
      setOperatorState([]);
    } else {
      setContractInfo({ ...contractInfo, [name]: value });
    }
  };

  /* ülkeler için allSelect buradan*/
  // const handleToggleOption = selectedOptions => {
  //   setContractInfo({ ...contractInfo, include_countries: selectedOptions });
  //   setSelectedOptions(selectedOptions);
  // };
  // const handleClearOptions = () => {
  //   setSelectedOptions([]);
  //   setContractInfo({ ...contractInfo, include_countries: [] });
  // };
  // const handleSelectAll = isSelected => {
  //   if (isSelected) {
  //     setSelectedOptions(allCountries);
  //   } else {
  //     setContractInfo({ ...contractInfo, include_countries: selectedOptions });

  //     handleClearOptions();
  //   }
  // };
  // const handleToggleSelectAll = () => {
  //   handleSelectAll && handleSelectAll(!allSelected);
  // };
  // const handleChangeForCountries = (event, selectedOptions, reason, selectedOption) => {
  //   if (reason === 'select-option' || reason === 'remove-option') {
  //     const selectAll = selectedOptions.find(option => option.value === 'select-all');
  //     if (selectedOption?.option?.name === 'Select All' && allCountries.length <= selectedOptions.length) {
  //       setContractInfo({ ...contractInfo, include_countries: [] });
  //       setSelectedOptions([]);
  //     } else {
  //       if (selectAll) {
  //         handleToggleSelectAll();
  //         let result = [];
  //         result = allCountries.filter(el => el.value !== 'select-all');
  //         return setContractInfo({
  //           ...contractInfo,
  //           include_countries: result,
  //         });
  //       } else {
  //         handleToggleOption && handleToggleOption(selectedOptions);
  //         return setContractInfo({
  //           ...contractInfo,
  //           include_countries: selectedOptions,
  //         });
  //       }
  //     }
  //   } else if (reason === 'clear') {
  //     handleClearOptions && handleClearOptions();
  //   }
  // };
  /* ülkeler için allSelect buraya*/

  const handleChange = event => {
    setUpdateButtonDisable(false);
    if (event.target.type === "checkbox" && event.target.name == "checkChild") {
      if (event.target.name === "checkChild" && event.target.checked == true) {
        setContractInfo({
          ...contractInfo,
          [event.target.name]: event.target.checked
        });
      } else {
        setContractInfo({
          ...contractInfo,
          [event.target.name]: event.target.checked,
          // disabledChild: event.target.checked,
          // disabledInf: event.target.checked,
          ageInf: 0
        });
      }
    } else if (event.target.type === "checkbox") {
      setContractInfo({
        ...contractInfo,
        [event.target.name]: event.target.checked
      });
    } else {
      setContractInfo({
        ...contractInfo,
        [event.target.name]: event.target.value
      });
    }
  };

  // const dateConfilict = (
  //   old_start_date,
  //   old_end_date,
  //   new_start_date,
  //   new_end_date
  // ) => {
  //   return (
  //     moment(new_start_date).isBetween(old_start_date, old_end_date) ||
  //     moment(new_start_date).isSame(old_end_date) ||
  //     moment(new_start_date).isSame(old_start_date) ||
  //     moment(new_end_date).isBetween(old_start_date, old_end_date) ||
  //     moment(new_end_date).isSame(old_start_date) ||
  //     moment(new_end_date).isSame(old_end_date)
  //   );
  // };

  // const isSame = (oldContract, newContract) => {
  //   const isDateConfilict = dateConfilict(
  //     oldContract.start_date,
  //     oldContract.end_date,
  //     newContract.start_date,
  //     newContract.end_date
  //   );

  //   // let isTheMarketInside = false;
  //   // newContract.market.map(element => {
  //   //   if (oldContract.market.includes(element)) {
  //   //     // return true;
  //   //     isTheMarketInside = true;
  //   //   } //else return false;
  //   // });
  //   let isTheCountryInside = false;
  //   newContract.include_countries.forEach((element) => {
  //     if (
  //       oldContract.contractCountries.findIndex(
  //         (oldCountry) => oldCountry.id === element.id
  //       ) !== -1
  //     ) {
  //       confilictCountries = confilictCountries + element.name + ", ";
  //       isTheCountryInside = true;
  //     } //else return false;
  //   });

  //   let isTheOperatorInside = false;
  //   newContract.operators.forEach((element) => {
  //     if (oldContract.operators.includes(element)) {
  //       confilictOpertors = confilictOpertors + operators[element].code + ", ";
  //       isTheOperatorInside = true;
  //       // return true;
  //     } /* else return false; */
  //   });
  //   if (
  //     oldContract.company_id === newContract.company_id &&
  //     oldContract.season_id === newContract.season_id &&
  //     oldContract.hotel_id === newContract.hotel_id
  //   ) {
  //     if (isDateConfilict) {
  //       if (
  //         /* isTheMarketInside && */ isTheCountryInside &&
  //         isTheOperatorInside
  //       ) {
  //         return true;
  //       } else if (
  //         /* !isTheMarketInside &&  */ (!isTheCountryInside &&
  //           isTheOperatorInside) ||
  //         /* isTheMarketInside &&  */ (isTheCountryInside &&
  //           !isTheOperatorInside)
  //       ) {
  //         return false;
  //       } else return false;
  //     } else return false;
  //   } else {
  //     return false;
  //   }
  // };

  const saveAndUpdateSeason = async () => {
    if (
      contractInfo.hasOwnProperty("base_board_id") &&
      contractInfo.base_board_id > 0 &&
      contractInfo.hasOwnProperty("rate_types") &&
      contractInfo.rate_types.length > 0 &&
      (!contractInfo.hasOwnProperty("checkChild") ||
      contractInfo.checkChild == false
        ? contractInfo.hasOwnProperty("max_child_age") &&
          contractInfo.max_child_age != "" &&
          contractInfo.min_child_age != null &&
          contractInfo.hasOwnProperty("max_child_age") &&
          contractInfo.min_child_age != "" &&
          contractInfo.min_child_age != null
        : true)
    ) {
      const res = await dispatch(controlContractConfilict(contractInfo));
      /* kontratın çakışma durumuna karşı backende istek atılıyor. 
      Oradan dönen sonuç true ise isThereContract değeri true yapılır(çakışan operator ya da ülkelerin isimleri gösteriliyor).
      False ise  isThereContract değeri false yapılır. */
      let isThereContract = false;
      if (res.data.result) {
        isThereContract = true;
        confilictCountries = res.data.countries.slice(0, -2);
        let contrr = res.data.contractName;
        confilictOpertors = res.data.operators.slice(0, -2);
        await sweetAlerts(
          "warning",
          confilictOpertors +
            (confilictOpertors.split(",").length > 1
              ? " coded operators"
              : " coded operator") +
            " and " +
            confilictCountries +
            (confilictCountries.split(",").length > 1
              ? " countries "
              : " country ") +
            contrr +
            " available for coded contract. Change operator or country and try again!"
        );
      }
      // let filteredContracts = contracts;
      contractInfo["company_id"] = selectedCompany?.id;
      // if (contractInfo.hasOwnProperty("id")) {
      //   //güncellenen bir kontrat ise kıyaslamaya gerek yok. Güncellenen filtrelendi.
      //   filteredContracts = Object.values(contracts).filter(
      //     (i) => i.id !== contractInfo.id
      //   );
      // }
      // if (filteredContracts) {
      //   Object.values(filteredContracts).map((oldContract) => {
      //     if (isSame(oldContract, contractInfo)) {
      //       isThereContract = true;
      //       confilictCountries = confilictCountries.slice(0, -2);
      //       confilictOpertors = confilictOpertors.slice(0, -2);
      //       sweetAlerts(
      //         "warning",
      //         (confilictOpertors.length > 0
      //           ? confilictOpertors +
      //             (confilictOpertors.split(",").length > 1
      //               ? " coded operators"
      //               : " coded operator")
      //           : "") +
      //           (confilictCountries.length > 0
      //             ? " and " +
      //               confilictCountries +
      //               (confilictCountries.split(",").length > 1
      //                 ? " countries "
      //                 : " country ")
      //             : "") +
      //           oldContract.contract_name +
      //           " available for coded contract. Change operator or country and try again!"
      //       );
      //       confilictCountries = "";
      //       confilictOpertors = "";
      //     } /* else return (isThereContract = false); */
      //   });
      // }
      if (!isThereContract) {
        if (
          contractInfo.hasOwnProperty("max_infant_age") &&
          contractInfo.hasOwnProperty("max_child_age") &&
          Number(contractInfo.max_infant_age) >=
            Number(contractInfo.max_child_age)
        ) {
          sweetAlerts(
            "warning",
            "Child age cannot be younger than infant age!"
          );
        } else if (
          (contractInfo.hasOwnProperty("max_infant_age") &&
            contractInfo.hasOwnProperty("max_child_age") &&
            contractInfo.max_child_age > 20) ||
          contractInfo.max_infant_age > 20
        ) {
          sweetAlerts(
            "warning",
            "Child age and infant age cannot be older than 20!"
          );
        } else if (
          moment(contractInfo.start_date).isAfter(contractInfo.end_date)
        ) {
          sweetAlerts("warning", "Start date cannot be later than end date!");
        } else if (
          contractInfo.start_date == "Invalid date" ||
          contractInfo.end_date == "Invalid date"
        ) {
          sweetAlerts("warning", "Please enter a valid date!");
        } else {
          let tempMarketsData = [];
          let include_countries = [];
          let exclude_countries = [];
          if (tempMarkets && contractInfo.include_countries) {
            tempMarkets.map(market => {
              include_countries = [];
              exclude_countries = [];
              ///BURASI DÜZELTİLECEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEKKKKKKKKKKKKK EXCLUDE YANLIŞ ÇALIŞIYO
              contractInfo.include_countries.map(in_country => {
                if (market.countries.includes(in_country.id)) {
                  include_countries.push(in_country);
                } else {
                  exclude_countries.push(countries[in_country.id]);
                }

                // if (market.countries[market.countries.findIndex(i => i === in_country.id)]) {
                //   market.countries.map(ex_country => {

                //     let countryCount = contractInfo.include_countries.filter(country => {
                //       return country.id == ex_country;
                //     });
                //     if (
                //       !countryCount.length > 0 &&
                //       market.countries.includes(ex_country) &&
                //       !exclude_countries.includes(ex_country.id)
                //     ) {
                //       exclude_countries.push(ex_country);
                //     }
                //   });
                //   market.countries.map(in_country => {
                //     let countryCount = contractInfo.include_countries.filter(country => {
                //       return country.id == in_country;
                //     });
                //     if (countryCount > 0 && market.countries.includes(in_country) && !include_countries.includes(in_country.id)) {

                //       include_countries.push(in_country);
                //     }
                //   });
                // }
              });
              tempMarketsData.push({
                market_id: market.id,
                includes: include_countries,
                excludes: exclude_countries
              });
            });
          }
          contractInfo["market"] = tempMarketsData;
          contractInfo["user"] = authUser;
          contractInfo["start_date"] = contractInfo.start_date;
          contractInfo["end_date"] = contractInfo.end_date;
          // contractInfo['start_date'] = new Date(contractInfo.start_date);
          // contractInfo['end_date'] = new Date(contractInfo.end_date);
          // contractInfo['company'] = selectedCompany?.id;

          let contract_name_temp = "";
          contract_name_temp = seasons[contractInfo.season_id].code;
          Object.values(contractInfo?.market).forEach(
            element =>
              (contract_name_temp =
                contract_name_temp + "-" + markets[element.market_id].code)
          );
          contract_name_temp =
            contract_name_temp + "-" + hotelInformation.hotel_code;
          contract_name_temp =
            contract_name_temp + "-" + currencies[contractInfo.currency].code;
          contractInfo["contract_name"] = contract_name_temp;
          contractInfo["office_id"] = officeID;

          if (selectedContract != 0) {
            dispatch(updateContractService(contractInfo));
          } else {
            dispatch(addContractService(contractInfo));
          }
          // const emptyValuesState = {};
          // Object.entries(contractInfo).forEach(element => {
          //   if (element[0] === 'rate_types' || element[0] === 'boards' || element[0] === 'market') {
          //     emptyValuesState[element[0]] = [];
          //     setContractInfo({ ...contractInfo, ...emptyValuesState });
          //   } else {
          //     emptyValuesState[element[0]] = '';
          //     setContractInfo({ ...contractInfo, ...emptyValuesState });
          //   }
          // });
          dispatch(isSavedConract("isSaved"));
        }
      }
    } else {
      sweetAlerts(
        "warning",
        intl.formatMessage({ id: "please.fill.in.all.fields" })
      );
    }
  };

  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: "95%",
      height: "100%",
      margin: "0 auto",
      fontSize: "12px!important",
      margin: "0 auto",
      fontSize: "12px!important",
      overflow: "inherit",
      "& .MuiInputLabel-root": {
        color: "black",
        fontWeight: "bold!important"
      },
      "& .MuiTypography-root": {
        color: "black!important", // or black
        fontWeight: "bold!important"
      }
    },
    formControl: {
      margin: theme.spacing(2),
      fontSize: "12px!important",
      minWidth: "85%",
      maxWidth: "400px"
    },
    rootcard: {
      minWidth: "100%",
      maxWidth: "100%",
      margin: "0 auto",
      fontSize: "12px!important",
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
      overflow: "inherit"
    },
    title: {
      fontSize: "12px!important"
    },
    formElement: {
      minWidth: "100%",
      fontSize: "10px!important",
      margin: theme.spacing(2)
    },
    container: {
      display: "flex",
      fontSize: "12px!important",
      flexWrap: "wrap"
    },
    button: {
      float: "right",
      margin: theme.spacing(2),
      fontSize: "12px!important",
      maxWidth: "50%",
      top: "-25px"
    },
    contractName: {
      textAlign: "center"
    },
    autocomplete: {
      "& .MuiInputBase-input": {
        color: "#000"
      }
    }
  }));

  const classes = useStyles();
  const allSelected = allCountries.length === selectedOptions.length;

  const countriesOperator = () => {
    let tempOperator = [];
    if (
      contractInfo.include_countries &&
      contractInfo.include_countries.length > 0
    ) {
      // get Ids of selected countries
      const contractCountryIds = contractInfo.include_countries.map(
        country => country.id
      );

      tempOperator = Object.values(operators).reduce((acc, operator) => {
        if (operator.countries.length > 0) {
          for (let i = 0; i < operator.countries.length; i++) {
            if (contractCountryIds.includes(operator.countries[i].id)) {
              acc.push(operator);
              break;
            }
          }
        }
        return acc;
      }, []);
    }
    return tempOperator;
  };

  /**** Calculate Max Child Age Here****/
  const maxChild = 19.99;
  const maxChildVal = [];

  let maxage = _.range(2.99, maxChild, 1);

  maxage.forEach(param => {
    maxChildVal.push({ age: param.toFixed(2) });
  });
  /*****Calculate Min Child Age****/
  const minChild = 19;
  const minChildVal = [];

  let minage = _.range(2, minChild, 1);

  minage.forEach(param => {
    minChildVal.push({ age: param.toFixed(2) });
  });

  /*************************************/

  const getHotelError = () => {
    if (props.type === "edit") {
      const getMachedHtel = hotelData.find(
        hotel => hotel.id == hotelInformation.id
      );
      if (getMachedHtel === undefined || hotelData.length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (hotelData.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const dataControl = () => {
    return contractInfo.hotel_id &&
      contractInfo.season_id &&
      contractInfo.currency &&
      contractInfo.allotment_type_id &&
      contractInfo.operators.length > 0 &&
      contractInfo.hasOwnProperty("include_countries") &&
      contractInfo.include_countries?.length > 0 &&
      contractInfo.boards.length > 0 &&
      contractInfo.market.length > 0
      ? false
      : true;
  };
  const filterOptions = (options, state) => {
    let newOptions = [];
    options.forEach(element => {
      if (element.name.toLowerCase().startsWith(state.inputValue.toLowerCase()))
        newOptions.push(element);
    });

    options.forEach(element => {
      if (
        element.name.toLowerCase().includes(state.inputValue.toLowerCase()) &&
        !element.name.toLowerCase().startsWith(state.inputValue.toLowerCase())
      ) {
        newOptions.push(element);
      }
    });

    return newOptions;
  };

  return (
    <div className={classes.root}>
      <InputBase
        inputProps={{
          style: {
            marginTop: "0.1%",
            textAlign: "center",
            color:
              localStorage.getItem("theme-type") === "dark" ? "white" : "black"
          }
        }}
        defaultValue={intl.formatMessage({ id: "contract.definitions" })}
        fullWidth
        disabled
        margin="dense"
      />
      <Card className={classes.root}>
        <CardContent className={classes.rootcard}>
          <Typography
            sx={{ fontSize: 18 }}
            className={classes.contractName}
            color="text.secondary"
            gutterBottom
          >
            <b>{contractInfo?.contract_name}</b>
          </Typography>
          <Grid container item xs={12} direction="row">
            <Grid item xs container direction="row" spacing={1}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item xs={12}>
                        {props.type !== "edit" ? (
                          <Autocomplete
                            options={companyInfo}
                            freeSolo
                            disabled={
                              authUser?.authority_level == 11 ||
                              authUser?.authority_level == 20 ||
                              authUser?.authority_level == 30
                            }
                            value={selectedCompany ? selectedCompany.name : ""}
                            onChange={(event, value) => {
                              // handleOnChange('company', value ? value?.id : {});
                              handleOnChange(
                                "company_id",
                                value ? value?.id : ""
                              );
                            }}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                              <TextField
                                {...params}
                                disabled={
                                  authUser?.authority_level == 11 ||
                                  authUser?.authority_level == 20 ||
                                  authUser?.authority_level == 30
                                }
                                className={classes.autocomplete}
                                //error={!contractInfo.company_id}
                                label={<IntlMessages id="company" />}
                                value={
                                  selectedCompany ? selectedCompany.name : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  readOnly: true
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  value: selectedCompany
                                    ? selectedCompany.name
                                    : ""
                                }}
                              />
                            )}
                          />
                        ) : (
                          <TextField
                            className={classes.formElement}
                            label={<IntlMessages id="company.name" />}
                            value={selectedCompany ? selectedCompany.name : ""}
                            InputLabelProps={{
                              shrink: true,
                              readOnly: true
                            }}
                          />
                        )}
                      </Grid>
                    </AccordionSummary>

                    <AccordionDetails hidden={selectedCompany == undefined}>
                      <Card
                        className={classes.rootcard}
                        hidden={
                          selectedContract != 0 ? false : contractInfo.hotelInfo
                        }
                      >
                        <CardContent>
                          <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            <FormControl className={classes.formControl}>
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="company.name" />}
                                defaultValue="Şirket ismi"
                                value={
                                  selectedCompany ? selectedCompany.name : ""
                                }
                                InputProps={{
                                  readOnly: true
                                }}
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="company.taxoffice" />}
                                defaultValue="Tax Ofis & No"
                                value={
                                  selectedCompany
                                    ? selectedCompany.tax_office
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true
                                }}
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="city" />}
                                defaultValue="City"
                                value={
                                  selectedCompany
                                    ? entities.city[selectedCompany.city_id]
                                        ?.name
                                    : ""
                                }
                                InputProps={{
                                  readOnly: true
                                }}
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label={<IntlMessages id="phone.number" />}
                                value={
                                  selectedCompany ? selectedCompany.phone : ""
                                }
                                defaultValue="Tel"
                                InputProps={{
                                  readOnly: true
                                }}
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label="Fax"
                                defaultValue="Fax"
                                value={
                                  selectedCompany ? selectedCompany.tax_no : ""
                                }
                                InputProps={{
                                  readOnly: true
                                }}
                              />
                              <TextField
                                className={classes.formElement}
                                id="outlined-read-only-input"
                                label="Email"
                                defaultValue="Email"
                                value={
                                  selectedCompany ? selectedCompany.email : ""
                                }
                                InputProps={{
                                  readOnly: true
                                }}
                              />
                            </FormControl>
                          </Typography>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item xs={12}>
                        {props.type !== "edit" ? (
                          <Autocomplete
                            options={hotelData}
                            freeSolo
                            // value={selectedCompany ? hotelData?.name : ''}
                            filterOptions={filterOptions}
                            value={
                              selectedCompany
                                ? hotelData.find(
                                    hData => hData.id == hotelInformation.id
                                  )
                                : ""
                            }
                            onChange={(event, value) => {
                              handleOnChange("hotel_id", value ? value : {});
                            }}
                            getOptionLabel={option => option.name}
                            renderInput={params => (
                              <TextField
                                {...params}
                                helperText={
                                  selectedCompany === undefined
                                    ? intl.formatMessage({
                                        id: "please.select.company.first"
                                      })
                                    : getHotelError()
                                    ? "No hotel found "
                                    : ""
                                }
                                label={<IntlMessages id="hotel" />}
                                required={!contractInfo.hotel_id}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                          />
                        ) : (
                          <TextField
                            className={classes.formElement}
                            label={<IntlMessages id="hotel" />}
                            value={hotelInformation.name}
                            InputLabelProps={{
                              shrink: true,
                              readOnly: true
                            }}
                          />
                        )}
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      hidden={
                        contractInfo.hasOwnProperty("hotel_id") &&
                        contractInfo.hotel_id != undefined
                          ? false
                          : true
                      }
                    >
                      <Card
                        className={classes.rootcard}
                        hidden={
                          selectedContract != 0 ? false : contractInfo.hotelInfo
                        }
                      >
                        <CardContent>
                          <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            <IntlMessages id="hotel.info" />
                          </Typography>
                          <FormControl className={classes.formControl}>
                            <Grid container item xs={12} direction="row">
                              <Grid
                                item
                                xs
                                container
                                direction="row"
                                spacing={1}
                              >
                                <Grid item xs={12}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="hotel.name" />}
                                    value={hotelInformation.name}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="tax.office" />}
                                    value={hotelInformation.tax_office}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label="No"
                                    value={hotelInformation.tax_no}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="star.rating" />}
                                    value={hotelInformation.star_rating}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    value={hotelInformation.region?.name}
                                    label={<IntlMessages id="region" />}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="country" />}
                                    value={hotelInformation.country?.name}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="city" />}
                                    value={hotelInformation.city?.name}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    value={hotelInformation.sub_city?.name}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="sub.city" />}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="address" />}
                                    value={hotelInformation.district}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="website" />}
                                    value={hotelInformation.web_page}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label={<IntlMessages id="phone.number" />}
                                    value={hotelInformation.tel}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label="Fax"
                                    value={hotelInformation.fax}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <TextField
                                    className={classes.formElement}
                                    id="outlined-read-only-input"
                                    label="Email"
                                    value={hotelInformation.e_mail}
                                    InputProps={{
                                      readOnly: true
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  {contractStatusControl ? (
                    <Autocomplete
                      value={
                        contractInfo.season_id
                          ? seasons[contractInfo.season_id]
                          : ""
                      }
                      options={Object.values(seasons).sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )}
                      onChange={(event, value) => {
                        handleOnChange("season_id", value ? value.id : "");
                      }}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required={
                            contractInfo.season_id != undefined ? false : true
                          }
                          label={<IntlMessages id="season.name" />}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      className={classes.formElement}
                      label={<IntlMessages id="season.name" />}
                      value={seasons[contractInfo.season_id].name}
                      InputLabelProps={{
                        readOnly: true
                      }}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                {console.log(contractInfo)}
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      name="start_date"
                      disablePast={
                        contractInfo.contract_statuses_id === 5 ? false : true
                      }
                      format="dd/MM/yyyy"
                      autoOk="true"
                      value={
                        contractInfo.season_id
                          ? moment(contractInfo.start_date).format("YYYY-MM-DD")
                          : moment(selectedStartDate).format("YYYY-MM-DD")
                      }
                      onChange={value => {
                        handleOnChange(
                          "start_date",
                          moment(value).format("YYYY-MM-DD")
                        );
                      }}
                      label={<IntlMessages id="start.date" />}
                      animateYearScrolling
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        readOnly: !contractStatusControl
                      }}
                      readOnly={!contractStatusControl}
                      disabled={contractInfo.contract_statuses_id === 5}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      name="end_date"
                      disablePast
                      format="dd/MM/yyyy"
                      autoOk="true"
                      //value={contractInfo.season_id ? contractInfo.end_date : selectedEndDate}
                      value={
                        contractInfo.season_id
                          ? moment(contractInfo.end_date).format("YYYY-MM-DD")
                          : moment(selectedEndDate).format("YYYY-MM-DD")
                      }
                      required={contractInfo.season_id ? false : true}
                      minDate={contractInfo.start_date}
                      onChange={value => {
                        handleOnChange(
                          "end_date",
                          moment(value).format("YYYY-MM-DD")
                        );
                      }}
                      label={<IntlMessages id="end.date" />}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        readOnly: !contractStatusControl
                      }}
                      readOnly={!contractStatusControl}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <MultiSelect
                    overrideStrings={{ allItemsAreSelected: "ALL Selected" }}
                    options={multiDataSetter(
                      "name",
                      "id",
                      Object.values(countryInMarket).sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                    )}
                    value={multiDataSetter("name", "id", tempMarkets)}
                    onChange={event => handleOnChange("market", event)}
                    label={<IntlMessages id="market" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <MultiSelect
                    overrideStrings={{ allItemsAreSelected: "ALL Selected" }}
                    options={multiDataSetter(
                      "name",
                      "id",
                      allCountries.sort((a, b) => (a.name > b.name ? 1 : -1))
                    )}
                    value={multiDataSetter(
                      "name",
                      "id",
                      contractInfo.include_countries
                    )}
                    label={<IntlMessages id="countries" />}
                    onChange={e => handleOnChange("country", e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <MultiSelect
                    overrideStrings={{ allItemsAreSelected: "ALL Selected" }}
                    options={multiDataSetter(
                      "name",
                      "id",
                      countriesOperator().sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                    )}
                    value={multiDataSetter("name", "id", operatorState)}
                    onChange={e => handleOnChange("operators", e)}
                    label={<IntlMessages id="operators" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <MultiSelect
                    overrideStrings={{ allItemsAreSelected: "ALL Selected" }}
                    options={multiDataSetter(
                      "code",
                      "id",
                      Object.values(boards)
                    )}
                    value={multiDataSetter("code", "id", boardState)}
                    onChange={event => handleOnChange("boards", event)}
                    label={<IntlMessages id="boards" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    style={{
                      color:
                        contractInfo.base_board_id >= 0 ? "black" : "#A3A3A3"
                    }}
                    id="demo-simple-select-label"
                  >
                    Base Board
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label={<IntlMessages id="base.board" />}
                    required={
                      contractInfo.base_board_id &&
                      (contractInfo.boards?.includes(
                        contractInfo.base_board_id
                      ) ||
                        contractInfo.boards?.includes(
                          String(contractInfo.base_board_id)
                        ))
                        ? false
                        : true
                    }
                    placeholder={intl.formatMessage({ id: "base.board" })}
                    style={{ width: "100%" }}
                    value={contractInfo.base_board_id}
                    onChange={e => {
                      handleOnChange("base_board_id", e.target.value);
                    }}
                    readOnly={!contractStatusControl}
                  >
                    {contractInfo.boards?.map(option => (
                      <MenuItem value={option}>{boards[option]?.code}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <MultiSelect
                    overrideStrings={{ allItemsAreSelected: "ALL Selected" }}
                    options={multiDataSetter(
                      "name",
                      "id",
                      Object.values(rateTypes)
                        .filter(rt => {
                          return rt.id == 1 || rt.id == 2;
                        })
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                    )}
                    value={multiDataSetter("name", "id", rateTypeState)}
                    label={<IntlMessages id="rate.type" />}
                    onChange={event => handleOnChange("rate_types", event)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    error={allotmentTypeState?.length > 0 ? false : true}
                    options={Object.values(allotmentType)}
                    value={
                      contractInfo.allotment_type_id ? allotmentTypeState : ""
                    }
                    onChange={(event, value) => {
                      handleOnChange("allotment_type_id", value ? value : {});
                    }}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={intl.formatMessage({
                          id: "allotment.type"
                        })}
                        // error={!contractInfo.allotment_type_id}
                        required={!contractInfo.allotment_type_id}
                        label={<IntlMessages id="allotment.type" />}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    disabled={!contractStatusControl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    limitTags={2}
                    options={Object.values(currencies).sort((a, b) =>
                      a.code > b.code ? 1 : -1
                    )}
                    value={contractInfo.currency ? currenciesState : ""}
                    onChange={(event, value) => {
                      handleOnChange("currency", value ? value : {});
                    }}
                    getOptionLabel={option => option.code}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={intl.formatMessage({
                          id: "currency"
                        })}
                        // error={!contractInfo.currency}
                        required={!contractInfo.currency}
                        label={<IntlMessages id="currency" />}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    disabled={!contractStatusControl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs container direction="row" spacing={1}>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      style={{ paddingTop: "1.2em" }}
                      control={
                        <Checkbox
                          checked={
                            contractInfo.checkChild
                              ? contractInfo.checkChild
                              : false
                          }
                          onChange={handleChange}
                          name="checkChild"
                          disabled={!contractStatusControl}
                        />
                      }
                      label={<IntlMessages id="only.adult" />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      defaultValue={contractInfo.min_child_age}
                      placeholder={intl.formatMessage({
                        id: "infant.age"
                      })}
                      label={<IntlMessages id="min.child.age" />}
                      name="min_child_age"
                      InputProps={{
                        inputComponent: AgeNumberFormatCustom,
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true,
                        readOnly: true
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      limitTags={2}
                      options={maxChildVal}
                      value={Object.values(maxChildVal).find(
                        item => item.age == contractInfo.max_child_age
                      )}
                      onChange={(e, value) => {
                        handleOnChange("max_child_age", value ? value.age : "");
                      }}
                      getOptionLabel={option => option.age}
                      renderInput={params => (
                        <TextField
                          sx={{
                            "& label": { display: "flex", fontSize: "14px" }
                          }}
                          {...params}
                          required={!contractInfo.max_child_age}
                          label={<IntlMessages id="max.child.age" />}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                      disabled={!contractStatusControl}
                      required={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      style={{ paddingTop: "1.3em" }}
                      control={
                        <Checkbox
                          checked={contractInfo.checkInf}
                          disabled={
                            /* contractInfo.disabledInf */ !contractStatusControl ||
                            contractInfo.checkChild
                          }
                          onChange={handleChange}
                          name="checkInf"
                        />
                      }
                      label={
                        <Typography style={{ fontSize: "12px" }}>
                          <IntlMessages id="should.infant.count.as.pax" />
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      defaultValue={contractInfo.max_infant_age}
                      placeholder={intl.formatMessage({
                        id: "infant.age"
                      })}
                      label={<IntlMessages id="infant.age" />}
                      name="max_infant_age"
                      InputProps={{
                        inputComponent: AgeNumberFormatCustom,
                        readOnly: true
                      }}
                      InputLabelProps={{
                        shrink: true,
                        readOnly: true
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <FormControl className={classes.button}>
            <ThemeProvider theme={theme}>
              {selectedContract != 0 ? (
                isAuthUpdate ? (
                  <Button
                    variant="contained"
                    disabled={updateButtonDisable || dataControl()}
                    color="secondary"
                    onClick={() => saveAndUpdateSeason()}
                    startIcon={<UpdateIcon />}
                    hidden={!contractStatusControl}
                    styles={classes.button}
                  >
                    UPDATE
                  </Button>
                ) : (
                  undefined
                )
              ) : isAuthAdd ? (
                <Button
                  variant="contained"
                  disabled={
                    dataControl()
                    // contractInfo.hotel_id &&
                    // contractInfo.season_id &&
                    // contractInfo.currency &&
                    // contractInfo.allotment_type_id &&
                    // contractInfo.operators.length > 0 &&
                    // contractInfo.hasOwnProperty('include_countries') &&
                    // contractInfo.include_countries?.length > 0 &&
                    // contractInfo.boards.length > 0 &&
                    // contractInfo.market.length > 0
                    //   ? false
                    //   : true
                  }
                  color="primary"
                  onClick={() => saveAndUpdateSeason()}
                  startIcon={<SaveIcon />}
                  hidden={!contractStatusControl}
                >
                  SAVE
                </Button>
              ) : (
                undefined
              )}
            </ThemeProvider>
          </FormControl>
        </CardContent>
      </Card>
    </div>
  );
}

export default ContractSeason;

export const MemoizedContractSeason = React.memo(ContractSeason);
